import type { ActivitySelectedInfoSubscriptionUiElement } from '@orus.eu/dimensions'

import { ActivitiesSelector, useDialogVisibility } from '@orus.eu/pharaoh'
import { useCallback, useMemo } from 'react'
import { ActivityDetailButton } from '../../backoffice/common/backoffice-quote-editor-v2/quote-editor-v2-risk-carrier-info'
import { ifStateProxy } from '../if-state-proxy'
import { RemoveMainActivityConfirmationDialog } from './activities-selector-element/RemoveMainActivityConfirmationDialog'

export const ActivitySelectedInfoSubscriptionUiElementBlock = ifStateProxy<ActivitySelectedInfoSubscriptionUiElement>(
  function ActivitySelectedInfoSubscriptionUiElementBlock({ stateProxy, uiElement, context }) {
    const activityRaw = stateProxy.read(uiElement.dimensions.activity)

    const mainActivity = useMemo(
      () => (activityRaw ? { id: activityRaw.activity, name: activityRaw.displayName, products: [] } : undefined),
      [activityRaw],
    )

    const state = useMemo(() => {
      return {
        activity: stateProxy.read(uiElement.dimensions.activity),
        mrphHiscoxActivitiesNames: stateProxy.read(uiElement.dimensions.mrphHiscoxActivitiesNames),
        rcphHiscoxActivitiesNames: stateProxy.read(uiElement.dimensions.rcphHiscoxActivitiesNames),
        rcphProductLabel: stateProxy.read(uiElement.dimensions.rcphProductLabel),
        mrpwWakamMainActivity: stateProxy.read(uiElement.dimensions.mrpwWakamMainActivity),
        rcdaAxeriaActivityNames: stateProxy.read(uiElement.dimensions.rcdaAxeriaActivityNames),
      }
    }, [stateProxy, uiElement.dimensions])

    const {
      show: showRemoveMainActivityDialog,
      hide: hideRemoveMainActivityDialog,
      visible: visibleRemoveMainActivityDialog,
    } = useDialogVisibility('remove-main-activity')

    const handleRemoveMainActivity = useCallback(() => {
      stateProxy.write(uiElement.dimensions.activity, null)
      hideRemoveMainActivityDialog()
    }, [stateProxy, uiElement, hideRemoveMainActivityDialog])

    return (
      <>
        {mainActivity ? (
          <ActivitiesSelector
            isSelfOnboarding={context === 'selfonboarding'}
            mainActivity={mainActivity}
            complementaryActivities={[]}
            onRemoveMainActivity={showRemoveMainActivityDialog}
            ActivityDetailButton={<ActivityDetailButton state={state} />}
          />
        ) : undefined}
        {visibleRemoveMainActivityDialog && (
          <RemoveMainActivityConfirmationDialog
            onClose={hideRemoveMainActivityDialog}
            onRemoveMainActivity={handleRemoveMainActivity}
          />
        )}
      </>
    )
  },
)
