import type { CompanyIdNumber } from '@orus.eu/company-id-number'
import { memo, useCallback, useMemo, useState, type ReactElement } from 'react'
import type { Agent, AgentActivity } from '../agent.type'
import { isAgentCompleted } from '../utils'
import { AgentModal } from './AgentsModal'

type AgentModalWrapperProps = {
  onClose: () => void
  onSubmitAgent: (agent: Agent) => void
  onDeleteAgent: (agent: Agent) => void
  mode: 'create' | 'update'
  agent: Agent
  isInBackoffice: boolean
  availableActivities: AgentActivity[]
}

export const AgentModalWrapper = memo<AgentModalWrapperProps>(function AgentModalWrapper(
  props: AgentModalWrapperProps,
): ReactElement {
  const { onSubmitAgent, onClose, onDeleteAgent, mode, agent, isInBackoffice, availableActivities } = props

  const canDelete = useMemo(() => {
    return mode === 'update'
  }, [mode])

  const [idleAgent, setIdleAgent] = useState<Agent>(agent)

  const updateAgent = useCallback(
    (update: Agent) => {
      const updateIdleAgent = { ...idleAgent, ...update }
      setIdleAgent(updateIdleAgent)
    },
    [idleAgent],
  )

  const canSubmit = useMemo(() => {
    if (!idleAgent) return false
    return isAgentCompleted(idleAgent)
  }, [idleAgent])

  const onSubmit = useCallback(() => {
    if (!canSubmit) return
    onSubmitAgent(idleAgent)
  }, [canSubmit, idleAgent, onSubmitAgent])

  const onDelete = useCallback(() => {
    if (!canDelete) return
    onDeleteAgent(idleAgent)
  }, [canDelete, onDeleteAgent, idleAgent])

  const handleChangeFirstName = useCallback((value: string) => updateAgent({ firstName: value }), [updateAgent])

  const handleChangeSiren = useCallback(
    (value: CompanyIdNumber | undefined) => updateAgent({ siren: value }),
    [updateAgent],
  )

  const handleChangeLastName = useCallback((value: string) => updateAgent({ lastName: value }), [updateAgent])

  const handleChangeStreet = useCallback((value: string) => updateAgent({ street: value }), [updateAgent])

  const handleChangeCity = useCallback((value: string) => updateAgent({ city: value }), [updateAgent])

  const handleChangeCountry = useCallback((value: string) => updateAgent({ country: value }), [updateAgent])

  const handleChangePostCode = useCallback((value: string) => updateAgent({ postCode: value }), [updateAgent])

  const handleChangeActivities = useCallback(
    (value: string) => {
      const agentActivities = idleAgent.activities ? [...idleAgent.activities] : []
      const activity = agentActivities.find((activity) => activity.id === value)
      if (activity) {
        const activitiesToRemove = agentActivities.filter((activity) => activity.id !== value)
        updateAgent({ activities: activitiesToRemove })
      } else {
        const displayName = availableActivities.find((activity) => activity.id === value)?.name

        if (!displayName) return
        updateAgent({
          activities: [
            ...agentActivities,
            {
              id: value,
              name: displayName,
            },
          ],
        })
      }
    },
    [idleAgent.activities, updateAgent, availableActivities],
  )

  return (
    <AgentModal
      availableActivities={availableActivities}
      onClose={onClose}
      onSubmit={onSubmit}
      onDelete={onDelete}
      onFirstNameUpdate={handleChangeFirstName}
      onLastNameUpdate={handleChangeLastName}
      onSirenUpdate={handleChangeSiren}
      onStreetUpdate={handleChangeStreet}
      onPostCodeUpdate={handleChangePostCode}
      onCityUpdate={handleChangeCity}
      onCountryUpdate={handleChangeCountry}
      onActivitiesUpdate={handleChangeActivities}
      agent={idleAgent}
      isInBackoffice={isInBackoffice}
    />
  )
})
