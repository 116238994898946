import type { SubsidiariesSelectorContext, Subsidiary } from './subsidiary.type'

export const isSubsidiaryCompleted = (subsidiary: Subsidiary, context: SubsidiariesSelectorContext): boolean => {
  if (context.type === 'funnel' && context.step === 'create') {
    if (subsidiary.companyName && subsidiary.activities && subsidiary.activities.length > 0) {
      return true
    }
  }

  if (
    subsidiary.companyName &&
    subsidiary.street &&
    subsidiary.postCode &&
    subsidiary.city &&
    subsidiary.country &&
    subsidiary.activities &&
    subsidiary.activities.length > 0 &&
    subsidiary.siren &&
    subsidiary.orias
  ) {
    return true
  }

  return false
}
