import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { CompanyIdNumber } from '@orus.eu/company-id-number'
import type { OriasImmatriculation } from '@orus.eu/dimensions'
import {
  Checkbox,
  CheckboxContainer,
  colorTokens,
  Dialog,
  FormRow,
  oriasTextFieldMapper,
  postcodeMapper,
  sirenMapper,
  spacing,
  Text,
  TextFieldFormField,
  ValidatedTextFieldFormField,
} from '@orus.eu/pharaoh'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { memo, useCallback, useMemo, type ChangeEvent } from 'react'
import type { SubsidiariesSelectorContext, Subsidiary } from '../subsidiary.type'
import { isSubsidiaryCompleted } from '../utils'

export type SubsidiaryActivity = {
  id: string
  name: string
}

type SubsidiaryModalProps = {
  subsidiary: Subsidiary
  onDelete: () => void
  onClose: () => void
  onSubmit: () => void
  onCompanyNameUpdate?: (value: string) => void
  onSirenUpdate?: (value: CompanyIdNumber | undefined) => void
  onOriasUpdate?: (value: OriasImmatriculation | undefined) => void
  onStreetUpdate?: (value: string) => void
  onPostCodeUpdate?: (value: string) => void
  onCityUpdate?: (value: string) => void
  onCountryUpdate?: (value: string) => void
  onActivityCheck?: (value: SubsidiaryActivity) => void
  onActivityUncheck?: (value: SubsidiaryActivity) => void
  context: SubsidiariesSelectorContext
  availableActivities: SubsidiaryActivity[]
}

export const SubsidiaryModal = memo<SubsidiaryModalProps>(function SubsidiaryModal(props) {
  const {
    subsidiary,
    onClose,
    onSubmit,
    onCompanyNameUpdate,
    onSirenUpdate,
    onOriasUpdate,
    onStreetUpdate,
    onPostCodeUpdate,
    onCityUpdate,
    onActivityCheck,
    onActivityUncheck,
    context,
    availableActivities,
  } = props

  const { companyName, siren, orias, street, postCode, city, country, activities } = subsidiary

  const sirenLater = siren?.type === 'later'
  const oriasLater = orias?.type === 'later'

  const handleCompanyNameUpdate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === companyName || !onCompanyNameUpdate) return
      onCompanyNameUpdate(event.target.value)
    },
    [onCompanyNameUpdate, companyName],
  )

  const handleSirenUpdate = useCallback(
    (event: CompanyIdNumber | undefined) => {
      if (event === siren || !onSirenUpdate) return
      onSirenUpdate(event)
    },
    [onSirenUpdate, siren],
  )

  const handleOriasUpdate = useCallback(
    (value: OriasImmatriculation | undefined) => {
      if (value === orias || !onOriasUpdate) return
      onOriasUpdate(value)
    },
    [onOriasUpdate, orias],
  )

  const handleStreetUpdate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === street || !onStreetUpdate) return
      onStreetUpdate(event.target.value)
    },
    [onStreetUpdate, street],
  )

  const handlePostCodeUpdate = useCallback(
    (value: string | undefined) => {
      if (value === postCode || !onPostCodeUpdate) return
      onPostCodeUpdate(value ?? '')
    },
    [onPostCodeUpdate, postCode],
  )

  const handleCityUpdate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === city || !onCityUpdate) return
      onCityUpdate(event.target.value)
    },
    [onCityUpdate, city],
  )

  const handleSirenLaterChange = useCallback(
    (value: boolean) => {
      if (value === sirenLater || !onSirenUpdate) return
      if (value) {
        onSirenUpdate({ type: 'later' })
      } else {
        onSirenUpdate(undefined)
      }
    },
    [onSirenUpdate, sirenLater],
  )

  const handleOriasLaterChange = useCallback(
    (value: boolean) => {
      if (value === oriasLater || !onOriasUpdate) return
      if (value) {
        onOriasUpdate({ type: 'later' })
      } else {
        onOriasUpdate(undefined)
      }
    },
    [onOriasUpdate, oriasLater],
  )

  const modalTitle = useMemo(() => {
    if (context.type === 'funnel') return 'Votre filiale'

    return subsidiary.id ? 'Modifier la filiale' : 'Ajouter une filiale'
  }, [context.type, subsidiary.id])

  const isCompanyNameFieldDisabled = useMemo(() => {
    if (context.type === 'funnel' && context.step === 'update') return true
    return false
  }, [context])

  const showActivitiesSelector = useMemo(() => {
    if (context.type === 'backoffice') return true
    if (context.step === 'create') return true
    return false
  }, [context])

  const showCompleteForm = useMemo(() => {
    if (context.type === 'backoffice') return true
    if (context.step === 'update') return true

    return false
  }, [context])

  const handleActivityChange = useCallback(
    (activity: SubsidiaryActivity, value: boolean) => {
      if (value) {
        onActivityCheck?.(activity)
      } else {
        onActivityUncheck?.(activity)
      }
    },
    [onActivityCheck, onActivityUncheck],
  )

  return (
    <Dialog
      title={modalTitle}
      onClose={onClose}
      onPrimaryAction={onSubmit}
      primaryActionLabel="Valider"
      primaryActionDisabled={!isSubsidiaryCompleted(subsidiary, context)}
      onSecondaryAction={onClose}
      secondaryActionLabel="Annuler"
      size="large"
    >
      <FormContainer>
        <FormRow>
          <TextFieldFormField
            aria-label="Dénomination sociale"
            size="large"
            label="Dénomination sociale"
            placeholder="Dénomination sociale"
            value={companyName}
            onChange={handleCompanyNameUpdate}
            disabled={isCompanyNameFieldDisabled}
          />
        </FormRow>

        {showCompleteForm ? (
          <>
            <FormRow>
              <div>
                <ValidatedTextFieldFormField<CompanyIdNumber | undefined>
                  disabled={sirenLater}
                  size={'large'}
                  label={'SIREN'}
                  value={siren}
                  placeholder={sirenLater ? 'En cours d’obtention' : '552178639'}
                  onChange={handleSirenUpdate}
                  mapper={sirenMapper}
                />

                <CheckboxContainer checked={sirenLater} onChange={handleSirenLaterChange} size="small">
                  En cours d’obtention
                </CheckboxContainer>
              </div>
              <div>
                <ValidatedTextFieldFormField<OriasImmatriculation | undefined>
                  disabled={oriasLater}
                  aria-label="ORIAS"
                  size="large"
                  label="ORIAS"
                  placeholder={oriasLater ? 'En cours d’obtention' : '21012345'}
                  value={orias}
                  onChange={handleOriasUpdate}
                  mapper={oriasTextFieldMapper}
                />
                <CheckboxContainer checked={oriasLater} onChange={handleOriasLaterChange} size="small">
                  En cours d’obtention
                </CheckboxContainer>
              </div>
            </FormRow>
            <FormRow>
              <TextFieldFormField
                aria-label="Numéro et nom de la rue"
                size="large"
                label="Numéro et nom de la rue"
                placeholder="Numéro et nom de la rue"
                value={street}
                onChange={handleStreetUpdate}
              />
              <ValidatedTextFieldFormField
                size="large"
                aria-label="Code postal de résidence du bénéficiaire"
                label="Code postal de résidence"
                placeholder="75009"
                onChange={handlePostCodeUpdate}
                value={postCode}
                mapper={postcodeMapper}
              />
            </FormRow>
            <FormRow>
              <TextFieldFormField
                aria-label="Ville"
                size="large"
                label="Ville"
                placeholder="Ville"
                value={city}
                onChange={handleCityUpdate}
              />
              <TextFieldFormField
                aria-label="Pays"
                size="large"
                label="Pays"
                placeholder="Pays"
                value={country}
                disabled
              />
            </FormRow>
          </>
        ) : (
          <></>
        )}
        {showActivitiesSelector ? (
          <div
            css={css`
              margin-top: ${spacing[50]};
            `}
          >
            <FormRow>
              <Text
                css={css`
                  flex-grow: 1;
                  margin-bottom: ${spacing[50]};
                `}
                variant="body2Medium"
              >
                Activités de la filiale
              </Text>
            </FormRow>
            <FormRow>
              <RowContainer variant="border" size="medium">
                {availableActivities.map((activity) => (
                  <Row
                    key={activity.id}
                    variant="basic"
                    css={css`
                      padding: ${spacing[50]};
                    `}
                    title={activity.name}
                    titleVariant={'body2'}
                    leftAvatar={
                      <Checkbox
                        size="small"
                        checked={activities?.some((a) => a.id === activity.id) ?? false}
                        onChange={(value) => handleActivityChange(activity, value)}
                      />
                    }
                  />
                ))}
              </RowContainer>
            </FormRow>
            {context.type === 'funnel' && context.step === 'create' && activities?.length === 0 ? (
              <Text
                css={css`
                  height: ${spacing[60]};
                  display: flex;
                  align-items: center;
                `}
                variant="body2"
                color={colorTokens['color-text-danger-secondary']}
              >
                Vous devez sélectionner au moins une activité
              </Text>
            ) : null}
          </div>
        ) : (
          <></>
        )}
      </FormContainer>
    </Dialog>
  )
})

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[50]};
  width: 100%;
`
