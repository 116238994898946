import { css, type SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { Select as MuiSelect, type SelectChangeEvent } from '@mui/material'
import { memo, useCallback, type ReactNode } from 'react'

import { useUiContext, type UiContext } from '../../../code-only/hooks/use-screen-variant.js'
import {
  colorTokens,
  cssPropsPerScreenVariantPerTextVariant,
  Icon,
  PIXELS_PER_ICON_SIZE,
  shadow,
  spacing,
  Text,
  type TextCssProps,
} from '../../../foundations/index.js'
import { Checkbox } from '../../controls/index.js'
import { RowContainer } from '../../rows/row-container.js'
import { Row } from '../../rows/row.js'

const ICON_SIZE = PIXELS_PER_ICON_SIZE['30']

export type SelectSize = 'small' | 'large'

export type MultipleSelectProps = {
  value: string | null
  values: readonly string[] | readonly number[]
  placeholder?: string
  labels: { [key: string]: string }
  onChange: (newValue: string | null) => void
  disabled?: boolean
  size: SelectSize
  variant?: UiContext
  fullWidth?: boolean
  selectedValues?: Array<string | number>
  /**
   * When provided add an extra entry at the beginning to allow clearing the field
   */
  nullLabel?: string
  className?: string
  ['aria-label']?: string

  error?: boolean

  renderValue?: (value: unknown) => ReactNode
}

type SelectMenuRowProps = {
  key: string
  value: string
  text?: string
}

export const SelectMenuRow = memo<SelectMenuRowProps>(function SelectMenuRow(props) {
  const { key, text } = props
  return (
    <div
      key={key}
      css={css`
        display: flex;
        padding: ${spacing['30']} ${spacing['50']};
        align-items: center;
        align-content: center;
        gap: 16px ${spacing['50']};
        align-self: stretch;
        flex-wrap: wrap;
      `}
    >
      <Text variant="body2">{text}</Text>
    </div>
  )
})

const AngleDownIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <Icon
      icon="angle-down-solid"
      size={'30'}
      className={className}
      css={css`
        top: auto !important;
        right: ${spacing[40]} !important;
        transition: transform 0.2s;
      `}
    />
  )
}

export const MultipleSelect = memo<MultipleSelectProps>(function MultipleSelect(props) {
  const {
    value,
    placeholder,
    disabled,
    onChange,
    size,
    variant,
    values,
    className,
    'aria-label': ariaLabel,
    error,
    fullWidth,
    selectedValues,
    labels,
    renderValue,
  } = props
  const screenVariant = useUiContext()

  const typography = cssPropsPerScreenVariantPerTextVariant['input'][variant ?? screenVariant]

  const handleChange = useCallback(
    (event: SelectChangeEvent<string | null>) => {
      const newValueString = event.target.value
      const newValue = newValueString === nullValue ? null : newValueString
      onChange(newValue)
    },
    [onChange],
  )

  const handleCheckboxChange = useCallback(
    (option: string | number) => {
      onChange(option.toString())
    },
    [onChange],
  )

  const placeHolderCss =
    placeholder && !value
      ? css`
          &::after {
            content: '${CSS.escape(placeholder)}';
            color: ${colorTokens['color-text-base-disable']};
            pointer-events: none;
            position: absolute;
            z-index: 1;
            padding: 0 calc(${spacing[40]} + ${ICON_SIZE}) 0 ${spacing[40]};
          }
        `
      : ''

  const isSelected = (option: string | number) => {
    if (selectedValues === undefined) return false
    return selectedValues.includes(option)
  }

  return (
    <StyledSelect
      className={className}
      inputProps={{ 'aria-label': ariaLabel }}
      disabled={disabled}
      fullWidth={fullWidth ?? true}
      value={value ?? ''}
      renderValue={renderValue}
      onChange={handleChange as (event: SelectChangeEvent<unknown>) => void}
      size={size === 'large' ? 'medium' : 'small'}
      orusSize={size}
      error={error}
      IconComponent={AngleDownIcon}
      typography={typography}
      placeholderCss={placeHolderCss}
      MenuProps={{
        style: {
          marginTop: spacing[30],
        },
        MenuListProps: {
          style: {
            padding: 0,
          },
        },
        PaperProps: {
          sx: {
            borderRadius: spacing['30'],
            border: `1px solid ${colorTokens['color-stroke-base']}`,
            boxShadow: shadow.bottom[30],
          },
          elevation: 0,
        },
      }}
    >
      <RowContainer variant="simple" size="medium">
        {values.map((option, index) => (
          <Row
            key={index}
            variant="basic"
            css={css`
              padding: ${spacing[40]};
            `}
            title={labels ? labels[option.toString()] : option.toString()}
            titleVariant={'body2'}
            leftAvatar={
              <Checkbox size="small" checked={isSelected(option)} onChange={() => handleCheckboxChange(option)} />
            }
            onClick={() => handleCheckboxChange(option)}
          />
        ))}
      </RowContainer>
    </StyledSelect>
  )
})

const selectDimensionsPerSize: {
  [size in SelectSize]: { height: string }
} = {
  small: {
    height: '32px',
  },
  large: {
    height: '48px',
  },
}

const StyledSelect = styled(MuiSelect, {
  shouldForwardProp: (propName) => !['placeholderCss', 'orusSize'].includes(propName),
})<{
  typography: TextCssProps
  placeholderCss: SerializedStyles | ''
  value: string
  orusSize: SelectSize
}>`
  vertical-align: middle;
  border-radius: ${spacing[30]};
  ${({ typography }) => typography}
  color: ${({ value }) =>
    !value || value === '' ? colorTokens['color-text-base-disable'] : colorTokens['color-text-base-main']};

  height: ${({ orusSize }) => selectDimensionsPerSize[orusSize].height};
  box-shadow: ${shadow.bottom['05']} !important;

  & .MuiSelect-select {
    background-color: ${colorTokens['color-bg-base-normal']} !important;
    padding: 0 !important;
  }

  & .MuiSelect-outlined {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: ${spacing[40]} !important;
  }

  & fieldset {
    border-color: ${colorTokens['color-stroke-base']};
  }

  &:hover {
    & fieldset {
      border-color: ${colorTokens['color-stroke-base-hover']} !important;
    }
  }

  &.Mui-error {
    & fieldset {
      border-color: ${colorTokens['color-stroke-danger']} !important;
    }
  }

  &.Mui-disabled {
    background-color: ${colorTokens['color-bg-base-disable']} !important;

    & .MuiSelect-select {
      background-color: ${colorTokens['color-bg-base-disable']} !important;
    }

    & fieldset {
      border-color: ${colorTokens['color-stroke-base-disable']} !important;
    }

    box-shadow: none !important;
    color: ${colorTokens['color-text-base-disable']};
    -webkit-text-fill-color: 'none';
  }

  &.Mui-focused {
    & fieldset {
      border-color: ${colorTokens['color-stroke-base-selected']} !important;
    }
  }

  ${({ placeholderCss }) => placeholderCss}
`

const nullValue = '__NULL__'
