import type { AddAgentsSubscriptionUiElement, RcpaAgent } from '@orus.eu/dimensions'
import { useCallback, useMemo, useState } from 'react'
import { v4 } from 'uuid'
import { ifStateProxy } from '../if-state-proxy'
import type { Agent } from './agents-selector-element/agent.type'
import { AgentsSelector } from './agents-selector-element/components/AgentsSelector'
import type { SubsidiaryActivity } from './subsidiaries-selector-element/components/SubsidiaryModal'

export const AddAgentsUiElementBlock = ifStateProxy<AddAgentsSubscriptionUiElement>(
  function AddAgentsSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const [agentsState, setAgentsState] = useState(stateProxy.readRequired(uiElement.dimensions.rcpaAgents))
    const availableActivitiesState = stateProxy.readRequired(uiElement.dimensions.rcpaAvailableAgentsActivities)

    const availableActivities: SubsidiaryActivity[] = useMemo(() => {
      return availableActivitiesState.map((activity) => ({
        id: activity.activity,
        name: activity.displayName,
      }))
    }, [availableActivitiesState])

    const agents: Agent[] = useMemo(() => {
      return agentsState.map<Agent>((agent) => ({
        activities: agent.activities?.map((activity) => ({
          id: activity.activity,
          name: activity.displayName,
        })),
        city: agent.address?.city ?? undefined,
        firstName: agent.firstName ?? undefined,
        lastName: agent.lastName ?? undefined,
        country: agent.address?.country ?? undefined,
        id: agent.id,
        postCode: agent.address?.postCode ?? undefined,
        siren: agent.siren ?? undefined,
        street: agent.address?.street ?? undefined,
      }))
    }, [agentsState])

    const handleDeleteAgent = useCallback(
      (agent: Agent) => {
        const newAgents = agentsState.filter((a) => a.id !== agent.id)
        stateProxy.write(uiElement.dimensions.rcpaAgents, newAgents)
        setAgentsState(newAgents)
      },
      [agentsState, stateProxy, uiElement.dimensions.rcpaAgents],
    )

    const handleSubmitAgent = useCallback(
      (agent: Agent) => {
        const newAgents = [...agentsState, agentViewToAgentModel(agent, undefined)]
        stateProxy.write(uiElement.dimensions.rcpaAgents, newAgents)
        setAgentsState(newAgents)
      },
      [agentsState, stateProxy, uiElement.dimensions.rcpaAgents],
    )

    const handleUpdateAgent = useCallback(
      (agent: Agent) => {
        const agentToUpdateIndex = agentsState.findIndex((a) => a.id === agent.id)
        if (agentToUpdateIndex === -1) return

        const updateAgents = [...agentsState]
        updateAgents[agentToUpdateIndex] = agentViewToAgentModel(agent, agent.id)

        stateProxy.write(uiElement.dimensions.rcpaAgents, updateAgents)
        setAgentsState(updateAgents)
      },
      [agentsState, stateProxy, uiElement.dimensions.rcpaAgents],
    )

    return (
      <AgentsSelector
        isInBackoffice={context === 'backoffice'}
        maxAgentsCount={5}
        agents={agents}
        availableActivities={availableActivities}
        onDeleteAgent={handleDeleteAgent}
        onSubmitAgent={handleSubmitAgent}
        onUpdateAgent={handleUpdateAgent}
      />
    )
  },
)

function agentViewToAgentModel(agent: Agent, id: string | undefined): RcpaAgent {
  return {
    activities:
      agent.activities?.map((activity) => ({
        activity: activity.id,
        displayName: activity.name,
      })) ?? [],
    address: {
      street: agent.street,
      postCode: agent.postCode,
      city: agent.city,
      country: agent.country,
    },
    id: id ?? v4(),
    lastName: agent.lastName,
    firstName: agent.firstName,
    siren: agent.siren ?? null,
  }
}
