import type { AddSubsidiariesSubscriptionUiElement, RcpaSubsidiary } from '@orus.eu/dimensions'
import { useCallback, useMemo, useState } from 'react'
import { v4 } from 'uuid'
import { ifStateProxy } from '../if-state-proxy'
import { SubsidiariesSelector } from './subsidiaries-selector-element/components/SubsidiariesSelector'
import type { SubsidiaryActivity } from './subsidiaries-selector-element/components/SubsidiaryModal'
import type { SubsidiariesSelectorContext, Subsidiary } from './subsidiaries-selector-element/subsidiary.type'

export const AddSubsidiariesUiElementBlock = ifStateProxy<AddSubsidiariesSubscriptionUiElement>(
  function AddSubsidiariesSubscriptionUiElementBlock({ uiElement, stateProxy, context, stepId }) {
    const [subsidiariesState, setSubsidiariesState] = useState(
      stateProxy.readRequired(uiElement.dimensions.rcpaSubsidiaries),
    )
    const [availableActivitiesState] = useState(
      stateProxy.readRequired(uiElement.dimensions.rcpaAvailableActivitiesForMultiActivity),
    )

    const selectorContext: SubsidiariesSelectorContext =
      context === 'backoffice'
        ? {
            type: 'backoffice',
          }
        : {
            type: 'funnel',
            step: stepId === 'rcpa-finalize-subsidiaries' ? 'update' : 'create',
          }

    const subsidiaries: Subsidiary[] = useMemo(() => {
      return subsidiariesState.map<Subsidiary>((subsidiary) => ({
        activities: subsidiary.activities?.map((activity) => ({
          id: activity.activity,
          name: activity.displayName,
        })),
        city: subsidiary.address?.city ?? undefined,
        companyName: subsidiary.name ?? undefined,
        country: subsidiary.address?.country ?? undefined,
        id: subsidiary.id,
        orias: subsidiary.oriasNumber ?? undefined,
        postCode: subsidiary.address?.postCode ?? undefined,
        street: subsidiary.address?.street ?? undefined,
        siren: subsidiary.siren ?? undefined,
      }))
    }, [subsidiariesState])

    const availableActivities: SubsidiaryActivity[] = useMemo(() => {
      return availableActivitiesState.map((activity) => ({
        id: activity.activity,
        name: activity.displayName,
      }))
    }, [availableActivitiesState])

    const onDeleteSubsidiary = useCallback(
      (subsidiary: Subsidiary) => {
        const newSubsidiaries = subsidiariesState.filter((s) => s.id !== subsidiary.id)
        stateProxy.write(uiElement.dimensions.rcpaSubsidiaries, newSubsidiaries)
        setSubsidiariesState(newSubsidiaries)
      },
      [stateProxy, subsidiariesState, uiElement.dimensions.rcpaSubsidiaries],
    )

    const onSubmitSubsidiary = useCallback(
      (subsidiary: Subsidiary) => {
        const newSubsidiary: RcpaSubsidiary = subsidiaryViewToSubsidiaryModel(subsidiary, undefined)
        stateProxy.write(uiElement.dimensions.rcpaSubsidiaries, [...subsidiariesState, newSubsidiary])
        setSubsidiariesState([...subsidiariesState, newSubsidiary])
      },
      [stateProxy, subsidiariesState, uiElement.dimensions.rcpaSubsidiaries],
    )

    const onUpdateSubsidiary = useCallback(
      (subsidiary: Subsidiary) => {
        const subsidiaryToUpdateIndex = subsidiariesState.findIndex((s) => s.id === subsidiary.id)
        if (subsidiaryToUpdateIndex === -1) return

        const updateSubsidiaries = [...subsidiariesState]
        updateSubsidiaries[subsidiaryToUpdateIndex] = subsidiaryViewToSubsidiaryModel(subsidiary, subsidiary.id)

        stateProxy.write(uiElement.dimensions.rcpaSubsidiaries, updateSubsidiaries)
        setSubsidiariesState(updateSubsidiaries)
      },
      [stateProxy, subsidiariesState, uiElement.dimensions.rcpaSubsidiaries],
    )

    return (
      <SubsidiariesSelector
        subsidiaries={subsidiaries}
        availableActivities={availableActivities}
        onDeleteSubsidiary={onDeleteSubsidiary}
        onSubmitSubsidiary={onSubmitSubsidiary}
        onUpdateSubsidiary={onUpdateSubsidiary}
        context={selectorContext}
      />
    )
  },
)

function subsidiaryViewToSubsidiaryModel(subsidiary: Subsidiary, id: string | undefined): RcpaSubsidiary {
  return {
    activities:
      subsidiary.activities?.map((activity) => ({
        activity: activity.id,
        displayName: activity.name,
      })) ?? [],
    address: {
      city: subsidiary.city,
      country: subsidiary.country,
      postCode: subsidiary.postCode,
      street: subsidiary.street,
    },
    id: id ?? v4(),
    name: subsidiary.companyName,
    oriasNumber: subsidiary.orias ?? null,
    siren: subsidiary.siren ?? null,
  }
}
