import { css } from '@emotion/react'
import { formatFrenchAddress1Line } from '@orus.eu/address'
import type { PlaceData } from '@orus.eu/dimensions'
import {
  borderStroke,
  colorTokens,
  SearchBar,
  spacing,
  Text,
  TextInputLabelWrapper,
  useDebounce,
} from '@orus.eu/pharaoh'
import { isSuccess } from '@orus.eu/result'
import { useEffect, useState, type ReactElement } from 'react'
import { buildPlaceSelectionUpdate, type SiretSearchElementProps } from './siret-search-helper'

type SearchOption = {
  id: string
  place: PlaceData
}

export function SiretBoSearchElement({ stateProxy, uiElement, handleSubmit }: SiretSearchElementProps): ReactElement {
  const [searchBarOptions, setSearchBarOptions] = useState<SearchOption[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(stateProxy.readRequired(uiElement.dimensions.placeSearchQuery))
  const [debouncedRawQuery] = useDebounce(inputValue, 1000)

  const writePlaceSearchQuery = stateProxy.useWrite(uiElement.dimensions.placeSearchQuery)
  const placesResult = stateProxy.read(uiElement.dimensions.placeSearchResults)

  useEffect(() => {
    setIsLoading(true)
  }, [inputValue])

  useEffect(() => {
    if (debouncedRawQuery) {
      writePlaceSearchQuery(debouncedRawQuery)
    }
  }, [debouncedRawQuery, writePlaceSearchQuery])

  useEffect(() => {
    setIsLoading(false)
    const places = placesResult && isSuccess(placesResult) ? placesResult.output : []
    setSearchBarOptions(
      places.map((place, index) => ({
        id: `${index}`,
        place,
      })),
    )
  }, [placesResult])

  return (
    <TextInputLabelWrapper label={uiElement.searchText} required={uiElement.required}>
      <SearchBar
        size="small"
        options={searchBarOptions}
        value={null}
        inputValue={inputValue}
        isLoading={isLoading}
        freeSolo={false}
        hasCustomEndAdornment={true}
        hidePopper={inputValue === '' || isLoading}
        getOptionLabel={(option) => option.place.siret}
        filterOptions={(options) => options}
        placeholder={'552 178 639 00132'}
        onInputChange={(_event, newValue) => setInputValue(newValue)}
        onChange={(_event, option) => {
          setInputValue('')
          if (option) handleSubmit(undefined, buildPlaceSelectionUpdate(option.place, uiElement))
        }}
        renderOption={(props, option) => {
          const { key, ...restProps } = props
          return (
            <li key={key} {...restProps} css={SearchOptionCss} aria-label="siret-search-option">
              <span
                css={css`
                  align-items: center;
                `}
              >
                <Text className="mostImportantText" variant="body2Medium">
                  {option.place.name ?? option.place.companyName ?? option.place.siret}
                </Text>
                <Text variant="body2" color={colorTokens['color-text-base-basic']}>
                  {option.place.siret}
                </Text>
                <Text variant="body2" color={colorTokens['color-text-base-basic']}>
                  {formatFrenchAddress1Line(option.place)}
                </Text>
              </span>
            </li>
          )
        }}
        clearInputValue={() => setInputValue('')}
      />
    </TextInputLabelWrapper>
  )
}

const SearchOptionCss = css`
  border-bottom: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']};
  display: flex;
  padding: ${spacing['30']} ${spacing['50']};
  align-items: center;
  gap: ${spacing['50']};
  align-self: stretch;

  cursor: pointer;

  &:hover {
    background-color: ${colorTokens['color-bg-base-hover']};
  }
`
