import styled from '@emotion/styled'
import type { QuotePriorityNotificationUiElement } from '@orus.eu/dimensions'
import { PersistentNotification, ProductBadge, spacing, Text, useCrash } from '@orus.eu/pharaoh'
import type { Product } from '@orus.eu/product'
import { useNavigate } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { trpc } from '../../../../client'
import { usePermissions } from '../../../../lib/use-permissions'
import { ifStateProxy } from '../if-state-proxy'

export const QuotePriorityNotificationUiElementBlock = ifStateProxy<QuotePriorityNotificationUiElement>(
  function QuotePriorityNotificationSubscriptionUiElementBlock({ uiElement, stateProxy, subscriptionId }) {
    const [notification, setNotification] = useState<{
      products: Product[]
      otherQuoteCreationDate: number
      subscriptionId: string
      organizationDisplayName: string
    } | null>(null)
    const dimension = uiElement.dimension
    const email = stateProxy.read(dimension)
    const crash = useCrash()
    const { type: userType } = usePermissions()
    const navigate = useNavigate()
    const isPartner = userType === 'partner'

    useEffect(() => {
      let cancelled = false

      if (!email) return

      trpc.subscriptions.getPriorityPendingSubscription.query({ email, subscriptionId }).then(
        (result) => {
          if (cancelled) return
          setNotification(result)
        },
        (err) => {
          if (cancelled) return
          crash(err)
        },
      )

      return () => {
        cancelled = true
      }
    }, [crash, email, subscriptionId])

    const dtfWithTime = new Intl.DateTimeFormat('fr', { dateStyle: 'long', timeStyle: 'medium' })

    return notification ? (
      <PersistentNotification
        variant="warning"
        title="Priorité au devis existant"
        actionButton={
          !isPartner
            ? {
                label: 'Accéder au devis',
                onClick: () =>
                  void navigate({
                    to: '/bak/v2-pending-subscriptions/$subscriptionId',
                    params: { subscriptionId: notification.subscriptionId },
                  }),
              }
            : undefined
        }
      >
        {isPartner ? (
          <Text>
            Un devis a déjà été effectué par Orus pour ce client en date du{' '}
            {dtfWithTime.format(notification.otherQuoteCreationDate)} pour le produit{' '}
            <StyledProductBadgeWrapper>
              {notification.products.map((product, index) => (
                <ProductBadge product={product} key={index} />
              ))}
            </StyledProductBadgeWrapper>
            . Afin d&apos;assurer une expérience client optimale et limiter les frictions, Orus garde la priorité sur le
            traitement de ce dossier.
          </Text>
        ) : (
          <Text>
            {notification.organizationDisplayName} a déjà proposé un devis{' '}
            <StyledProductBadgeWrapper>
              {notification.products.map((product, index) => (
                <ProductBadge product={product} key={index} />
              ))}
            </StyledProductBadgeWrapper>{' '}
            à ce client le {dtfWithTime.format(notification.otherQuoteCreationDate)}. Afin d&apos;assurer une expérience
            client optimale et limiter les frictions, {notification.organizationDisplayName} garde la priorité sur le
            traitement de ce dossier.
          </Text>
        )}
      </PersistentNotification>
    ) : (
      <></>
    )
  },
)

const StyledProductBadgeWrapper = styled.span`
  display: inline-flex;
  gap: ${spacing[20]};
`
