import {
  mutaSocialSecurityRegimeEntries,
  type DisabledMutaSocialSecurityRegimeDimensionSubscriptionUiElement,
} from '@orus.eu/dimensions'
import { RowContainerWithRadio } from '@orus.eu/pharaoh'
import { useMemo } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const DisabledMutaSocialSecurityRegimeTypeDimensionSubscriptionUiElementBlock =
  ifStateProxy<DisabledMutaSocialSecurityRegimeDimensionSubscriptionUiElement>(
    function DisabledMutaSocialSecurityRegimeTypeDimensionSubscriptionUiElementBlock({ uiElement, stateProxy }) {
      const value = stateProxy.read(uiElement.dimension)
      const regimeEntry = useMemo(() => mutaSocialSecurityRegimeEntries.find((entry) => entry[0] === value), [value])
      const regimeRadioItem = useMemo(
        () => (regimeEntry ? [{ title: regimeEntry[1].label, tooltip: regimeEntry[1].tooltip }] : []),
        [regimeEntry],
      )
      return <RowContainerWithRadio items={regimeRadioItem} />
    },
  )
