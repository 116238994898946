import { getInstallmentFeeRateByRate, type InstallmentFeeSliderUiElement } from '@orus.eu/dimensions'

import { css } from '@emotion/react'
import { financialRateToNumber, newFinancialRate, ratesEqual } from '@orus.eu/amount'
import { TechnicalError } from '@orus.eu/error'
import { FlexColumn, Slider, spacing, Text, TextInputLabelWrapper } from '@orus.eu/pharaoh'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const InstallmentFeeSliderElementBlock = ifStateProxy<InstallmentFeeSliderUiElement>(
  function InstallmentFeeSliderElementBlock({ uiElement, stateProxy }) {
    const installmentFee = stateProxy.read(uiElement.dimensions.installmentFee)
    const defaultInstallmentFeeRate = stateProxy.read(uiElement.dimensions.defaultInstallmentFeeRate)
    const isInstallmentFeeRateEditable = stateProxy.read(uiElement.dimensions.isInstallmentFeeRateEditable)

    const installmentFeeRateNumberValue = useMemo(
      () => (installmentFee && installmentFee !== 'none' ? financialRateToNumber(installmentFee.rate) * 100 : 0),
      [installmentFee],
    )

    const [installmentFeeRateNumber, setInstallmentFeeRateNumber] = useState<number>(installmentFeeRateNumberValue)

    const label = useMemo(() => {
      if (installmentFee === 'none' && defaultInstallmentFeeRate === 'none')
        return 'Pas de frais de fractionnement (paiement annuel)'

      if (
        defaultInstallmentFeeRate &&
        defaultInstallmentFeeRate !== 'none' &&
        installmentFee &&
        installmentFee !== 'none' &&
        ratesEqual(defaultInstallmentFeeRate.rate, installmentFee.rate)
      )
        return 'Frais de fractionnement par défaut'

      return 'Frais de fractionnement (modifiés)'
    }, [installmentFee, defaultInstallmentFeeRate])

    useEffect(() => {
      setInstallmentFeeRateNumber(installmentFeeRateNumberValue)
    }, [installmentFeeRateNumberValue])

    const handleChange = useCallback(
      (value: number) => {
        setInstallmentFeeRateNumber(value)
        const newValue = value === 0 ? 'none' : getInstallmentFeeRateByRate(newFinancialRate(value / 100))

        if (!newValue) {
          throw new TechnicalError('Unexpected value received for installmentFeeRate from Slider component', {
            context: { value },
          })
        }

        stateProxy.write(uiElement.dimensions.manuallySetInstallmentFeeRate, newValue)
      },
      [stateProxy, uiElement.dimensions.manuallySetInstallmentFeeRate],
    )

    return (
      <TextInputLabelWrapper label={label}>
        <FlexColumn
          css={css`
            align-items: center;
            margin: 0;
            gap: ${spacing[10]};
          `}
        >
          <Slider
            min={0}
            max={15}
            step={5}
            value={installmentFeeRateNumber}
            onChange={handleChange}
            disabled={!isInstallmentFeeRateEditable}
            aria-label="installment fee slider"
          />
          <Text>{installmentFeeRateNumber} %</Text>
        </FlexColumn>
      </TextInputLabelWrapper>
    )
  },
)
