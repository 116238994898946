import { css } from '@emotion/react'

import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import type {
  DetailedSheetOverviewUiElement,
  OverviewActivitySectionState,
  OverviewGlobalNoteSectionState,
  OverviewInsuranceHistoryTimelineSectionState,
  OverviewSectionState,
  OverviewTimelineSectionState,
} from '@orus.eu/dimensions'
import type { File } from '@orus.eu/frontend/src/components/pages/subscription-v2/elements/documents-list-element/types'
import { spacing, useDialogVisibility } from '@orus.eu/pharaoh'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { trpcReact } from '../../../../../client'
import { usePermissions } from '../../../../../lib/use-permissions'

import { getCompanyIdNumberSirenValue } from '@orus.eu/company-id-number'
import { getCollectedFileDownloadLink } from '../../../../../lib/download-links'
import { useNavigateBack } from '../../../../../lib/hooks/use-navigate-back'
import { FileViewerDialog, type ViewerFile } from '../../../backoffice/common/FileViewerDialog'
import OverviewActivitySection from '../../../backoffice/detailed-sheet-page/components/overview/OverviewActivitySection'
import OverviewDocumentsSection from '../../../backoffice/detailed-sheet-page/components/overview/OverviewDocumentsSection'
import OverviewGlobalInfoSection from '../../../backoffice/detailed-sheet-page/components/overview/OverviewGlobalInfoSection'
import OverviewGlobalNoteSection from '../../../backoffice/detailed-sheet-page/components/overview/OverviewGlobalNoteSection'
import OverviewInsuranceHistoryTimelineSection from '../../../backoffice/detailed-sheet-page/components/overview/OverviewInsuranceHistoryTimelineSection'
import OverviewTimelineSection from '../../../backoffice/detailed-sheet-page/components/overview/OverviewTimelineSection'
import type { ComplianceData } from '../../../backoffice/detailed-sheet-page/detailed-sheet.types'
import { getComplianceData } from '../../../backoffice/detailed-sheet-page/helpers/get-compliance-data'
import { ifStateProxy } from '../../if-state-proxy'

export const DetailedSheetOverviewUiElementBlock = ifStateProxy<DetailedSheetOverviewUiElement>(
  function DetailedSheetOverviewUiElementBlock({ stateProxy, uiElement, subscriptionId }) {
    const isPlatform = usePermissions().type === 'platform'
    const [lastComplianceData, setLastComplianceData] = useState<ComplianceData>({
      complyAdvantageCustomersCase: [],
      lystaCompany: undefined,
      pappersCompany: undefined,
    })

    const { companyIdNumber } = uiElement.dimensions
    const companyIdNumberValue = stateProxy.read(companyIdNumber)

    const handleChanges = useCallback(
      (newValue: OverviewGlobalNoteSectionState) => {
        if (newValue.detailedSheetGlobalSynthesis) {
          stateProxy.write(uiElement.dimensions.detailedSheetGlobalSynthesis, newValue.detailedSheetGlobalSynthesis)
        }
        if (newValue.detailedSheetFirstLevelResponsible) {
          stateProxy.write(
            uiElement.dimensions.detailedSheetFirstLevelResponsible,
            newValue.detailedSheetFirstLevelResponsible,
          )
        }
        if (newValue.detailedSheetSecondLevelResponsible) {
          stateProxy.write(
            uiElement.dimensions.detailedSheetSecondLevelResponsible,
            newValue.detailedSheetSecondLevelResponsible,
          )
        }
        if (newValue.detailedSheetThirdLevelResponsible) {
          stateProxy.write(
            uiElement.dimensions.detailedSheetThirdLevelResponsible,
            newValue.detailedSheetThirdLevelResponsible,
          )
        }
      },
      [stateProxy, uiElement.dimensions],
    )

    const activitiesOverviewState: OverviewActivitySectionState = {
      activitiesDistribution: stateProxy.read(uiElement.dimensions.activitiesDistribution),
      activities: stateProxy.read(uiElement.dimensions.activities),
      detailedSheetActivitySynthesis: stateProxy.read(uiElement.dimensions.detailedSheetActivitySynthesis),
      detailedSheetAcagSynthesis: stateProxy.read(uiElement.dimensions.detailedSheetAcagSynthesis),
    }

    const globalInfoOverviewState: OverviewSectionState = {
      firstName: stateProxy.read(uiElement.dimensions.firstName),
      lastName: stateProxy.read(uiElement.dimensions.lastName),
      siretData: stateProxy.read(uiElement.dimensions.siret),
      companyIdNumber: stateProxy.read(uiElement.dimensions.companyIdNumber),
      companyName: stateProxy.read(uiElement.dimensions.companyName),
      companyCreationDate: stateProxy.read(uiElement.dimensions.companyCreationDate),
      estimatedRevenue: stateProxy.read(uiElement.dimensions.estimatedRevenue),
      workforce: stateProxy.read(uiElement.dimensions.workforce),
      rcdaBossExperience: stateProxy.read(uiElement.dimensions.rcdaBossExperience),
    }

    const globalNoteOverviewState: OverviewGlobalNoteSectionState = {
      detailedSheetGlobalSynthesis: stateProxy.read(uiElement.dimensions.detailedSheetGlobalSynthesis),
      detailedSheetFirstLevelResponsible: stateProxy.read(uiElement.dimensions.detailedSheetFirstLevelResponsible),
      detailedSheetSecondLevelResponsible: stateProxy.read(uiElement.dimensions.detailedSheetSecondLevelResponsible),
      detailedSheetThirdLevelResponsible: stateProxy.read(uiElement.dimensions.detailedSheetThirdLevelResponsible),
    }

    const timelineOverviewState: OverviewTimelineSectionState = {
      detailedSheetExperienceSynthesis: stateProxy.read(uiElement.dimensions.detailedSheetExperienceSynthesis),
    }

    const insuranceHistoryTimelineOverviewState: OverviewInsuranceHistoryTimelineSectionState = {
      detailedSheetHistorySynthesis: stateProxy.read(uiElement.dimensions.detailedSheetHistorySynthesis),
    }

    const detailedSheetExperienceStatus = stateProxy.read(uiElement.dimensions.detailedSheetExperienceStatus)

    const neededSubscriptionDocuments = stateProxy.read(uiElement.dimensions.neededSubscriptionDocuments)

    const viewerDialog = useDialogVisibility('detailed-sheet-viewer')
    const [selectedFileId, setSelectedFileId] = useState<string | undefined>(undefined)
    const [selectedCategory, setSelectedCategory] = useState<GroupCategory | 'none' | undefined>(undefined)

    const navigateBack = useNavigateBack()
    const collectedFiles = trpcReact.collectedFile.getAllFiles.useQuery({ subscriptionId })
    const filesPerCategory = Object.values(collectedFiles.data?.files ?? {}).reduce<
      Partial<Record<GroupCategory | 'none', File[]>>
    >((acc, file) => {
      if (file.deleted) return acc
      const groupCategory = (file.groupId ? collectedFiles.data?.groups[file.groupId]?.category : 'none') ?? 'none'
      acc[groupCategory] = (acc[groupCategory] ?? []).concat(file)
      return acc
    }, {})

    const allFilesSortedByCategory = useMemo(() => {
      const fileIndexPerCategory: Map<GroupCategory | 'none', number> = new Map()
      return Object.values(collectedFiles.data?.files ?? {})
        .flatMap<ViewerFile>((file) => {
          if (file.deleted) return []
          const group = file.groupId ? collectedFiles.data?.groups[file.groupId] : undefined
          const fileIndexForCategory = fileIndexPerCategory.get(group?.category ?? 'none') ?? 1
          fileIndexPerCategory.set(group?.category ?? 'none', fileIndexForCategory + 1)

          return [
            {
              uri: getCollectedFileDownloadLink(file.id),
              fileType: file.mimeType,
              metadata: {
                file,
                fileIndexForCategory,
                group: group ?? null,
              },
            },
          ]
        })
        .sort((a, b) => {
          const aCategory = a.metadata.group?.category ?? 'none'
          const bCategory = b.metadata.group?.category ?? 'none'
          if (aCategory !== bCategory) {
            return aCategory < bCategory ? -1 : 1
          }
          return a.metadata.fileIndexForCategory - b.metadata.fileIndexForCategory
        })
    }, [collectedFiles.data?.files, collectedFiles.data?.groups])

    const selectedFile = useMemo(() => {
      return allFilesSortedByCategory.find((file) => file.metadata.file.id === selectedFileId)
    }, [allFilesSortedByCategory, selectedFileId])

    const statusPerCategory = useMemo(
      () =>
        Object.values(collectedFiles.data?.groups ?? {}).reduce<Partial<Record<GroupCategory | 'none', GroupStatus>>>(
          (acc, group) => {
            const groupCategory = group.category ?? 'none'
            acc[groupCategory] = group.status
            return acc
          },
          {},
        ),
      [collectedFiles],
    )

    useEffect(() => {
      const indexOfSelectedCategory = allFilesSortedByCategory.findIndex(
        (file) => file.metadata.group?.category === selectedCategory,
      )
      setSelectedFileId(allFilesSortedByCategory[indexOfSelectedCategory]?.metadata.file.id)
    }, [allFilesSortedByCategory, selectedCategory])

    const handleCloseFileViewerDialog = () => {
      navigateBack()
      viewerDialog.hide()
    }

    const handleSelectedCategoryChange = (category: GroupCategory) => {
      setSelectedCategory(category)
      const indexOfSelectedCategory = allFilesSortedByCategory.findIndex(
        (file) => file.metadata.group?.category === category,
      )
      setSelectedFileId(allFilesSortedByCategory[indexOfSelectedCategory]?.metadata.file.id)
      viewerDialog.show()
    }

    const handleFileClick = (file: File) => {
      setSelectedFileId(file.id)
      viewerDialog.show()
    }

    const siren = getCompanyIdNumberSirenValue(companyIdNumberValue) ?? ''
    const lastComplianceDataQuery = trpcReact.compliance.getLastComplianceData.useQuery({
      siren,
      subscriptionId,
    })

    useEffect(() => {
      if (siren) {
        const complianceData = getComplianceData(lastComplianceDataQuery.data)

        setLastComplianceData(complianceData)
      }
    }, [lastComplianceDataQuery.data, siren])

    const experienceFiles = []

    if (filesPerCategory.invoices) {
      experienceFiles.push(...filesPerCategory.invoices)
    }
    if (filesPerCategory.experience) {
      experienceFiles.push(...filesPerCategory.experience)
    }

    const showAcag =
      activitiesOverviewState.detailedSheetAcagSynthesis != null &&
      activitiesOverviewState.detailedSheetAcagSynthesis !== ''

    return isPlatform ? (
      <>
        {selectedFile && viewerDialog.visible ? (
          <FileViewerDialog
            selectedFile={selectedFile}
            onSelectedFileChange={(file) => setSelectedFileId(file.metadata.file.id)}
            allFiles={allFilesSortedByCategory}
            totalForCategory={filesPerCategory[selectedFile.metadata.group?.category ?? 'none']?.length ?? 0}
            allowedCategories={neededSubscriptionDocuments ?? []}
            close={handleCloseFileViewerDialog}
            onReviewFinished={null}
            invalidate={() => {
              void collectedFiles.refetch()
            }}
          />
        ) : null}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[30]};
          `}
        >
          <OverviewGlobalInfoSection state={globalInfoOverviewState} complianceData={lastComplianceData} />
          <OverviewActivitySection state={activitiesOverviewState} showAcagComment={showAcag} />
          <OverviewDocumentsSection
            profile={detailedSheetExperienceStatus ?? 'other'}
            groupStatusByCategory={statusPerCategory}
            files={experienceFiles}
            onIdFileGroupStatusClick={handleSelectedCategoryChange}
            onFileClick={handleFileClick}
            showAcag={showAcag}
          />
          {detailedSheetExperienceStatus === 'coq' || detailedSheetExperienceStatus === 'aigle' ? (
            <OverviewInsuranceHistoryTimelineSection state={insuranceHistoryTimelineOverviewState} />
          ) : null}
          <OverviewTimelineSection state={timelineOverviewState} />
          <OverviewGlobalNoteSection state={globalNoteOverviewState} setChanges={handleChanges} isReadOnly={false} />
        </div>
      </>
    ) : (
      <></>
    )
  },
)
