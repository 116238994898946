import { addAmounts, multiplyByNumber, multiplyByRate, zeroAmount, type Amount } from '@orus.eu/amount'
import type { EsRcphQuote } from '@orus.eu/pricing'
import type { MrphQuoteV2, MutaQuote, RcdaQuote, RcphQuoteV2 } from '@orus.eu/quote'
import type { PartialDimensionnedState } from '../abstract-dimension'
import { esRcphQuoteDimension } from '../es-rcph'
import { mrphQuoteV2Dimension } from '../mrph'
import { mrpwQuoteDimension, type MrpwQuote } from '../mrpw'
import { mutaQuoteDimension } from '../muta'
import type { PaymentRecurrence } from '../payement-recurrence-dimension'
import { rcdaQuoteDimension } from '../rcda'
import { rcphQuoteV2Dimension } from '../rcph'

export function getMprwQuoteEffectiveYearlyBasePremium(quote: MrpwQuote, paymentRecurrence: PaymentRecurrence): Amount {
  return paymentRecurrence === 'yearly' ? quote.yearlyBasePremium : multiplyByNumber(quote.monthlyBasePremium, 12)
}

export function getQuoteEffectiveYearlyBasePremium(
  quote: MutaQuote | RcdaQuote | RcphQuoteV2 | MrphQuoteV2,
  paymentRecurrence: PaymentRecurrence,
): Amount {
  return paymentRecurrence === 'yearly'
    ? quote.yearly.withoutAnyDiscount.total.exTax
    : quote.monthly.withoutAnyDiscount.total.annualizedExTax
}

export function getMrpwQuoteBasePremiumForRecurrence(quote: MrpwQuote, paymentRecurrence: PaymentRecurrence): Amount {
  return paymentRecurrence === 'yearly' ? quote.yearlyBasePremium : quote.monthlyBasePremium
}

export function getQuoteBasePremiumForRecurrence(
  quote: MutaQuote | RcdaQuote | RcphQuoteV2 | MrphQuoteV2 | EsRcphQuote,
  paymentRecurrence: PaymentRecurrence,
): Amount {
  return quote[paymentRecurrence].final.total.exTax
}

export const partnerQuoteDimensions = [
  mutaQuoteDimension,
  mrpwQuoteDimension,
  mrphQuoteV2Dimension,
  rcdaQuoteDimension,
  rcphQuoteV2Dimension,
  esRcphQuoteDimension,
] as const
export type PartnerQuotesDimensions = PartialDimensionnedState<typeof partnerQuoteDimensions>

/**
 * Returns the effective yearly broker commission, taking into account the payment recurrence
 */
export function getEffectiveYearlyPartnerCommissionAmount(
  state: PartnerQuotesDimensions,
  paymentReccurence: PaymentRecurrence,
): Amount {
  let partnerCommissionAmount = zeroAmount

  if (state.mrpwQuote && state.mrpwQuote.type === 'success') {
    if (!state.mrpwQuote.output.partnerCommissionRate) {
      return zeroAmount
    }
    partnerCommissionAmount = addAmounts(
      partnerCommissionAmount,
      multiplyByRate(
        getMprwQuoteEffectiveYearlyBasePremium(state.mrpwQuote.output, paymentReccurence),
        state.mrpwQuote.output.partnerCommissionRate,
      ),
    )
  }

  for (const quote of [state.mutaQuote, state.rcdaQuote, state.rcphQuoteV2, state.mrphQuoteV2]) {
    if (quote) {
      if (!quote.partnerCommissionRate) {
        return zeroAmount
      }
      partnerCommissionAmount = addAmounts(
        partnerCommissionAmount,
        multiplyByRate(getQuoteEffectiveYearlyBasePremium(quote, paymentReccurence), quote.partnerCommissionRate),
      )
    }
  }

  return partnerCommissionAmount
}

export function getPartnerCommissionAmountByRecurrence(
  state: PartnerQuotesDimensions,
  paymentReccurence: PaymentRecurrence,
): Amount {
  let partnerCommissionAmount = zeroAmount

  if (state.mrpwQuote && state.mrpwQuote.type === 'success') {
    if (!state.mrpwQuote.output.partnerCommissionRate) return zeroAmount
    partnerCommissionAmount = addAmounts(
      partnerCommissionAmount,
      multiplyByRate(
        getMrpwQuoteBasePremiumForRecurrence(state.mrpwQuote.output, paymentReccurence),
        state.mrpwQuote.output.partnerCommissionRate,
      ),
    )
  }

  for (const quote of [state.mutaQuote, state.rcdaQuote, state.rcphQuoteV2, state.mrphQuoteV2, state.esRcphQuote]) {
    if (quote) {
      if (!quote.partnerCommissionRate) return zeroAmount
      partnerCommissionAmount = addAmounts(
        partnerCommissionAmount,
        multiplyByRate(getQuoteBasePremiumForRecurrence(quote, paymentReccurence), quote.partnerCommissionRate),
      )
    }
  }

  return partnerCommissionAmount
}
