import { oriasImmatriculationRegex, type OriasImmatriculation } from '@orus.eu/dimensions'
import { failure, success } from '@orus.eu/result'
import type { ValidatedTypeMapper } from '../index.js'

export const oriasTextFieldMapper: ValidatedTypeMapper<OriasImmatriculation | undefined> = {
  inputType: 'text',
  format: formatValueForTextInput,
  formatPlaceholder: formatValueForTextInput,
  parse: (text) => {
    text = text.trim()
    if (text === '') return success(undefined)
    if (!oriasImmatriculationRegex.test(text)) {
      return failure('Entrez les 8 chiffres du numéro ORIAS')
    }
    return success({ type: 'provided', oriasId: text })
  },
}

function formatValueForTextInput(value: OriasImmatriculation | undefined): string {
  if (!value) return ''
  if (value.type === 'later') return ''
  return value.oriasId
}
