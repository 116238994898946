import styled from '@emotion/styled'
import type { CompanyIdNumber } from '@orus.eu/company-id-number'
import {
  CheckboxContainer,
  Dialog,
  FormRow,
  postcodeMapper,
  sirenMapper,
  spacing,
  Text,
  TextFieldFormField,
  ValidatedTextFieldFormField,
} from '@orus.eu/pharaoh'
import { memo, useCallback, useMemo, type ChangeEvent } from 'react'
import type { Agent, AgentActivity } from '../agent.type'
import { isAgentCompleted } from '../utils'
import { MultipleDropdownSelectableField } from './MultipleDropdownSelectableField'

type AgentModalProps = {
  agent: Agent
  onDelete: () => void
  onClose: () => void
  onSubmit: () => void
  onFirstNameUpdate?: (value: string) => void
  onLastNameUpdate?: (value: string) => void
  onActivityUpdate?: (value: string) => void
  onActivitiesUpdate?: (value: string) => void
  onSirenUpdate?: (value: CompanyIdNumber | undefined) => void
  onStreetUpdate?: (value: string) => void
  onPostCodeUpdate?: (value: string) => void
  onCityUpdate?: (value: string) => void
  onCountryUpdate?: (value: string) => void
  isInBackoffice: boolean
  availableActivities: AgentActivity[]
}

export const AgentModal = memo<AgentModalProps>(function AgentModal(props) {
  const {
    agent,
    onClose,
    onSubmit,
    onFirstNameUpdate,
    onLastNameUpdate,
    onSirenUpdate,
    onStreetUpdate,
    onPostCodeUpdate,
    onCityUpdate,
    onActivitiesUpdate,
    isInBackoffice,
    availableActivities,
  } = props

  const { firstName, lastName, siren, street, postCode, city, country } = agent

  const sirenLater = siren?.type === 'later'

  const handleFirstNameUpdate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === firstName || !onFirstNameUpdate) return
      onFirstNameUpdate(event.target.value)
    },
    [onFirstNameUpdate, firstName],
  )

  const handleLastNameUpdate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === lastName || !onLastNameUpdate) return
      onLastNameUpdate(event.target.value)
    },
    [onLastNameUpdate, lastName],
  )

  const handleSirenUpdate = useCallback(
    (event: CompanyIdNumber | undefined) => {
      if (event === siren || !onSirenUpdate) return
      onSirenUpdate(event)
    },
    [onSirenUpdate, siren],
  )

  const handleStreetUpdate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === street || !onStreetUpdate) return
      onStreetUpdate(event.target.value)
    },
    [onStreetUpdate, street],
  )

  const handlePostCodeUpdate = useCallback(
    (event: string | undefined) => {
      if (event === postCode || !onPostCodeUpdate) return
      onPostCodeUpdate(event ?? '')
    },
    [onPostCodeUpdate, postCode],
  )

  const handleCityUpdate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === city || !onCityUpdate) return
      onCityUpdate(event.target.value)
    },
    [onCityUpdate, city],
  )

  const handleSirenLaterChange = useCallback(
    (value: boolean) => {
      if (value === sirenLater || !onSirenUpdate) return
      if (value) {
        onSirenUpdate({ type: 'later' })
      } else {
        onSirenUpdate(undefined)
      }
    },
    [onSirenUpdate, sirenLater],
  )

  const handleActivitiesUpdate = useCallback(
    (value: string | null) => {
      if (!onActivitiesUpdate) return

      onActivitiesUpdate(value ?? '')
    },
    [onActivitiesUpdate],
  )

  const modalTitle = useMemo(() => {
    if (!isInBackoffice) return 'Votre mandataire'

    return agent.id ? 'Modifier le mandataire' : 'Ajouter un mandataire'
  }, [isInBackoffice, agent])

  return (
    <Dialog
      title={modalTitle}
      onClose={onClose}
      onPrimaryAction={onSubmit}
      primaryActionLabel="Valider"
      primaryActionDisabled={!isAgentCompleted(agent)}
      onSecondaryAction={onClose}
      secondaryActionLabel="Annuler"
      size="large"
    >
      <FormContainer>
        <FormRow>
          <TextFieldFormField
            aria-label="Prénom"
            size="large"
            label="Prénom"
            placeholder="Prénom"
            value={firstName}
            onChange={handleFirstNameUpdate}
            required
          />
          <TextFieldFormField
            aria-label="Nom"
            size="large"
            label="Nom"
            placeholder="Nom"
            value={lastName}
            onChange={handleLastNameUpdate}
            required
          />
        </FormRow>
        <FormRow>
          <MultipleDropdownSelectableField
            required
            size="large"
            label="Activités"
            value={agent.activities?.[0]?.id ?? ''}
            renderValue={(_value) => (
              <Text>
                {agent.activities && agent.activities.length > 0
                  ? agent.activities.length > 1
                    ? `${agent.activities.length} activités sélectionnées`
                    : `${agent.activities.length} activité sélectionnée`
                  : 'Aucune activité sélectionnée'}
              </Text>
            )}
            placeholder={
              agent.activities && agent.activities.length > 0
                ? agent.activities.length > 1
                  ? `${agent.activities.length} activités sélectionnées`
                  : `${agent.activities.length} activité sélectionnée`
                : 'Aucune activité sélectionnée'
            }
            onChange={handleActivitiesUpdate}
            values={availableActivities.map((activity) => activity.id)}
            labels={Object.fromEntries(availableActivities.map((activity) => [activity.id, activity.name]))}
            selectedValues={agent.activities?.map((activity) => activity.id)}
          />
          <div>
            <ValidatedTextFieldFormField<CompanyIdNumber | undefined>
              disabled={sirenLater}
              size={'large'}
              label={'SIREN'}
              value={siren}
              placeholder={sirenLater ? 'En cours d’obtention' : '552178639'}
              onChange={handleSirenUpdate}
              mapper={sirenMapper}
              required
            />

            <CheckboxContainer checked={sirenLater} onChange={handleSirenLaterChange} size="small">
              En cours d’obtention
            </CheckboxContainer>
          </div>
        </FormRow>
        <FormRow>
          <TextFieldFormField
            aria-label="Numéro et nom de la rue"
            size="large"
            label="Numéro et nom de la rue"
            placeholder="Numéro et nom de la rue"
            value={street}
            onChange={handleStreetUpdate}
            required
          />
          <ValidatedTextFieldFormField
            size="large"
            aria-label="Code postal de résidence du bénéficiaire"
            label="Code postal de résidence"
            placeholder="75009"
            onChange={handlePostCodeUpdate}
            value={postCode}
            mapper={postcodeMapper}
            required
          />
        </FormRow>
        <FormRow>
          <TextFieldFormField
            aria-label="Ville"
            size="large"
            label="Ville"
            placeholder="Ville"
            value={city}
            onChange={handleCityUpdate}
            required
          />
          <TextFieldFormField aria-label="Pays" size="large" label="Pays" placeholder="Pays" value={country} disabled />
        </FormRow>
      </FormContainer>
    </Dialog>
  )
})

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[50]};
  width: 100%;
`
