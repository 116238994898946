import { type ActivitiesDistributionSubscriptionUiElement } from '@orus.eu/dimensions'
import {
  ActivitiesDistribution,
  TextInputLabelWrapper,
  useLanguage,
  useScreenType,
  type Activity,
} from '@orus.eu/pharaoh'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ifStateProxy } from '../../if-state-proxy'
import { computeIsValid, mapActivitiesToDistribution } from './activities-distribution-element.utils'

export const ActivitiesDistributionSubscriptionUiElementBlock =
  ifStateProxy<ActivitiesDistributionSubscriptionUiElement>(
    function ActivitiesDistributionSubscriptionUiElementBlock(props) {
      const language = useLanguage()
      const { uiElement, stateProxy, registerNextEnabledContribution } = props

      const screenType = useScreenType()
      const activitiesRaw = stateProxy.readRequired(uiElement.dimensions.activities)
      const activitiesDistribution = stateProxy.read(uiElement.dimensions.activitiesDistribution)
      const estimatedRevenue = stateProxy.readRequired(uiElement.dimensions.estimatedRevenue)
      const writeActivitiesDistribution = stateProxy.useWrite(uiElement.dimensions.activitiesDistribution)

      const mappedActivities: Activity[] = useMemo(
        () => mapActivitiesToDistribution(activitiesRaw, activitiesDistribution),
        [activitiesRaw, activitiesDistribution],
      )

      const [draftActivities, setDraftActivities] = useState(mappedActivities)

      const writeValidatedActivitiesDistribution = useCallback(
        (activities: Activity[]) => {
          const isValid = computeIsValid(activities)

          writeActivitiesDistribution(
            isValid
              ? {
                  type: 'multiple',
                  distribution: Object.fromEntries(
                    activities.map((activity) => [activity.id, { sharePercentage: activity.sharePercentage }]),
                  ),
                }
              : null,
          )
        },
        [writeActivitiesDistribution],
      )

      const handleDraftActiviesUpdateOrCommit = useCallback(
        (activities: Activity[]) => {
          setDraftActivities(activities)
          writeValidatedActivitiesDistribution(activities)
        },
        [writeValidatedActivitiesDistribution],
      )

      useEffect(() => {
        const isValid = computeIsValid(draftActivities)

        registerNextEnabledContribution('activities-distribution-checker', isValid)
        return () => {
          registerNextEnabledContribution('activities-distribution-checker', true)
        }
      }, [draftActivities, registerNextEnabledContribution])

      useEffect(() => {
        setDraftActivities((draftActivities) => {
          const draftActivitiesIds = new Set(draftActivities.map((activity) => activity.id))

          const areSameActivities =
            draftActivities.length === mappedActivities.length &&
            mappedActivities.every((activity) => draftActivitiesIds.has(activity.id))

          if (!areSameActivities) return mappedActivities

          return draftActivities
        })
      }, [mappedActivities])

      return (
        <TextInputLabelWrapper
          label={uiElement.dimensions.activitiesDistribution.displayNames[language]}
          infoTooltip={
            uiElement.dimensions.activitiesDistribution.hints
              ? uiElement.dimensions.activitiesDistribution.hints[language]
              : undefined
          }
        >
          <ActivitiesDistribution
            screenType={screenType}
            revenue={estimatedRevenue}
            activities={draftActivities}
            onUpdate={handleDraftActiviesUpdateOrCommit}
            onCommit={handleDraftActiviesUpdateOrCommit}
          />
        </TextInputLabelWrapper>
      )
    },
  )
