import { isActivity } from '@orus.eu/activity'
import type { DisplayedGroupsUi } from '@orus.eu/backend/src/views/activity-grouping-view'
import {
  activityRelatedInputDimensionsToReset,
  type ActivityCategorySearchSubscriptionUiElement,
} from '@orus.eu/dimensions'
import { TechnicalError, ensureError } from '@orus.eu/error'
import { NotFoundError, useAsyncCallback, useCrash } from '@orus.eu/pharaoh'
import { useEffect, useState } from 'react'
import { trpc } from '../../../../client'
import { selfOnboardingGroupMappingLoader } from '../../../../lib/group-mapping-util'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { ActivitySearchField } from '../../../organisms/activity-search-field'
import { ifStateProxy } from '../if-state-proxy'
import { useHandleExclusionProblem } from '../use-handle-exclusion-problem'

export const ActivityCategorySearchSubscriptionUiElementBlock =
  ifStateProxy<ActivityCategorySearchSubscriptionUiElement>(
    function ActivityCategorySearchSubscriptionUiElementBlock(props) {
      const handleExclusionProblem = useHandleExclusionProblem()
      const { stateProxy, uiElement, handleSubmit, goBackToPreviousStep, subscriptionId, stepId } = props
      const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)
      const category = stateProxy.readRequired(uiElement.dimensions.searchCategory)
      const crash = useCrash()
      const [defaultActivityList, setDefaultActivityList] = useState<DisplayedGroupsUi[]>([])

      useEffect(() => {
        if (category === 'bypassed') {
          return
        }
        let cancelled = false
        ;(async () => {
          const { mostImportantGroupActivities } =
            await trpc.selfOnboarding.getGroupsWithSelfOnboardingForCategory.query(category)

          if (cancelled) return
          setDefaultActivityList(mostImportantGroupActivities)
        })().catch((err: unknown) => {
          if (cancelled) return
          crash(ensureError(err))
        })

        return () => {
          cancelled = true
        }
      }, [category, crash])

      const handleActivitySelected = useAsyncCallback(
        async (group: string) => {
          if (!group) return
          const groupDescription = await selfOnboardingGroupMappingLoader.getGroupMapping({ group, operatingZone })
          if (!groupDescription) {
            handleExclusionProblem({
              operatingZone,
              subscriptionId,
              stepId,
              exclusion: {
                name: 'genericActivityExclusion',
                message: {
                  key: 'exclusion_activity',
                  parameters: { description: group },
                },
              },
            })
            return
          }
          const { mainActivityId } = groupDescription
          if (!isActivity(mainActivityId)) {
            throw new TechnicalError('Invalid activity selected in category search', {
              context: { message: mainActivityId },
            })
          }

          handleSubmit(undefined, {
            [uiElement.dimensions.activity.name]: { activity: mainActivityId, displayName: group },
            ...activityRelatedInputDimensionsToReset,
          })
        },
        [handleSubmit, operatingZone, uiElement, subscriptionId, stepId, handleExclusionProblem],
      )

      if (category === 'bypassed') {
        crash(new NotFoundError())
        return <GlobalLoadingState />
      }

      return (
        <ActivitySearchField
          operatingZone={operatingZone}
          defaultActivityList={defaultActivityList}
          onActivitySelected={handleActivitySelected}
          onCategoryChanged={goBackToPreviousStep}
          filteredCategory={category}
          subscriptionId={subscriptionId}
          autoFocus
        />
      )
    },
  )
