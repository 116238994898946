import type { IntegerSubscriptionUiElement } from '@orus.eu/dimensions'
import { TagBadge, useLanguage, ValidatedTextFieldFormField } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../if-state-proxy'
import { mappers } from './integer-element-mappers.utils'

export const IntegerSubscriptionUiElementBlock = ifStateProxy<IntegerSubscriptionUiElement>(
  function IntegerSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const language = useLanguage()
    const dimension = uiElement.dimension
    const value = stateProxy.read(dimension)
    const handleChange = stateProxy.useWrite(dimension)

    const mapper = mappers[uiElement.type]

    return (
      <ValidatedTextFieldFormField
        size={context === 'backoffice' ? 'small' : 'large'}
        badge={context === 'backoffice' ? <TagBadge tag={uiElement.dimension.tags?.[0]} /> : <></>}
        label={dimension.displayNames[language]}
        value={value}
        placeholder={dimension.placeholders ? dimension.placeholders[language] : undefined}
        onChange={handleChange}
        mapper={mapper}
        infoTooltip={dimension.hints ? dimension.hints[language] : undefined}
        aria-label={dimension.ariaLabels ? dimension.ariaLabels[language] : undefined}
        required={uiElement.required}
        highlight={uiElement.highlight}
      />
    )
  },
)
