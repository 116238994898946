import { css } from '@emotion/react'
import type { CustomClausesUiElement } from '@orus.eu/dimensions'
import { Avatar, Button, colorTokens, Dialog, spacing, Text, useDialogVisibility } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../../if-state-proxy'
import { getNumberOfCustomClauses } from './custom-clauses-utils'
import { CustomClausesDimensionFields } from './CustomClausesDimensionFields'

export const CustomClausesUiElementBlock = ifStateProxy<CustomClausesUiElement>(function CustomClausesUiElementBlock({
  stateProxy,
}) {
  const {
    hide: hideCustomClausesDialog,
    show: showCustomClausesDialog,
    visible: isCustomClausesDialogVisible,
  } = useDialogVisibility('custom-editor')

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Avatar icon="circle-info-solid" size="30" color={colorTokens['color-fg-warning']} />
        <Text
          variant="body2"
          css={css`
            padding: ${spacing[40]};
          `}
        >
          {getNumberOfCustomClauses(stateProxy) > 1
            ? `${getNumberOfCustomClauses(stateProxy)} clauses personnalisées ajoutées dans l'attestation et les CP.`
            : `${getNumberOfCustomClauses(stateProxy)} clause personnalisée ajoutée dans l'attestation et les CP.`}
        </Text>
      </div>
      <Button
        variant="secondary"
        size="small"
        icon="pen-regular"
        avatarPosition="left"
        onClick={showCustomClausesDialog}
      >
        Modifier
      </Button>
      {isCustomClausesDialogVisible && (
        <Dialog
          size="large"
          title="Clauses personnalisables"
          secondaryActionLabel="Terminer"
          onSecondaryAction={hideCustomClausesDialog}
          onClose={hideCustomClausesDialog}
        >
          <CustomClausesDimensionFields stateProxy={stateProxy} />
        </Dialog>
      )}
    </div>
  )
})
