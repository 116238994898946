import styled from '@emotion/styled'
import { deepEqualAssimilateNullUndefined, type BankAccountSubscriptionUiElement } from '@orus.eu/dimensions'
import {
  FlexColumn,
  IBAN_MAPPER,
  IbanFormatter,
  spacing,
  TagBadge,
  ValidatedTextFieldFormField,
  type ValidatedTypeMapper,
} from '@orus.eu/pharaoh'
import { failure, success } from '@orus.eu/result'
import { translate } from '@orus.eu/translations'
import { isValidBIC } from 'ibantools'
import { useEffect, useState } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const BankAccountSubscriptionUiElementBlock = ifStateProxy<BankAccountSubscriptionUiElement>(
  function SocialSecurityNumberSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const bankAccountDimension = uiElement.dimension
    const value = stateProxy.read(bankAccountDimension)
    const writeBankAccountDimension = stateProxy.useWrite(bankAccountDimension)

    const [iban, setIban] = useState<string | undefined>(value?.iban)
    const [bic, setBic] = useState<string | undefined>(value?.bic)

    useEffect(() => {
      const desiredBankAccountInformation = bic && iban ? { bic, iban } : undefined
      if (!deepEqualAssimilateNullUndefined(desiredBankAccountInformation, value)) {
        writeBankAccountDimension(desiredBankAccountInformation)
      }
    }, [iban, bic, value, bankAccountDimension, writeBankAccountDimension])

    return (
      <BankAccountContainer>
        <ValidatedTextFieldFormField
          size={context === 'backoffice' ? 'small' : 'large'}
          label="IBAN"
          badge={context === 'backoffice' ? <TagBadge tag={bankAccountDimension.tags?.[0]} /> : <></>}
          value={iban}
          placeholder="FR76 0123 4567 8901 2345 6789 088"
          onChange={setIban}
          mapper={IBAN_MAPPER}
          slotProps={{
            input: {
              inputComponent: IbanFormatter,
            },
          }}
          caption="Exemple : FR76 0123 4567 8901 2345 6789 088"
        />

        <ValidatedTextFieldFormField
          size={context === 'backoffice' ? 'small' : 'large'}
          label="BIC"
          badge={context === 'backoffice' ? <TagBadge tag={bankAccountDimension.tags?.[0]} /> : <></>}
          value={bic}
          placeholder="BOUSFRPPXXX"
          onChange={setBic}
          mapper={BIC_MAPPER}
          caption="Exemple : BOUSFRPPXXX"
        />
      </BankAccountContainer>
    )
  },
)

const BIC_MAPPER: ValidatedTypeMapper<string> = {
  inputType: 'text',
  format: (value) => value.trim(),
  formatPlaceholder: (value) => value.trim(),
  parse: (text, language) => {
    return isValidBIC(text) ? success(text.trim()) : failure(translate('error_enter_bic', language))
  },
}

const BankAccountContainer = styled(FlexColumn)`
  margin: 0;
  gap: ${spacing[70]};

  width: 100%;
  max-width: 760px;
`
