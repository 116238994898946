import { type SubscriptionTimelineElement } from '@orus.eu/dimensions'
import { useSearch } from '@tanstack/react-router'
import { Timeline } from '../../../backoffice/common/timeline/Timeline'
import { ifStateProxy } from '../../if-state-proxy'

const QUOTE_HEADER_CONTAINER_HEIGHT = '111px'

export const SubscriptionTimelineUiElementBlock = ifStateProxy<SubscriptionTimelineElement>(
  function SubscriptionTimelineUiElementBlock({ subscriptionId }) {
    const { timeline_id: timelineId, thread_id: threadId, tab_id: tabId } = useSearch({ strict: false })

    return (
      <Timeline
        subscriptionId={subscriptionId}
        timelineId={timelineId}
        threadId={threadId}
        tabId={tabId}
        timelineHeight={`calc(100vh - ${QUOTE_HEADER_CONTAINER_HEIGHT})`}
      />
    )
  },
)
