import { css } from '@emotion/react'
import { computePercentageChange, ratesEqual, rateToNumber, rateToPercentString, X0 } from '@orus.eu/amount'
import {
  getCummulatedMonthlyTotalPremiumPerYear,
  globalMonthlyCommitmentPenaltyTenPercentRate,
  installmentFeeRateDimension,
  rcpaInstallmentFeeRateDimension,
  rcphInstallmentFeeRateDimension,
  type AggregatedQuote,
  type PaymentRecurrence,
  type PaymentRecurrenceSubscriptionUiElement,
  type TypeOfDimension,
} from '@orus.eu/dimensions'
import {
  Avatar,
  PersistentNotification,
  spacing,
  TabBar,
  Text,
  Tooltip,
  useScreenType,
  useTranslate,
  VirtualClickableArea,
} from '@orus.eu/pharaoh'
import { memo, useCallback, useMemo } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const PaymentRecurrenceElementBlock = ifStateProxy<PaymentRecurrenceSubscriptionUiElement>(
  function PaymentRecurrenceElementBlock({ uiElement, stateProxy }) {
    const quote = stateProxy.read(uiElement.dimensions.quote)
    return quote ? (
      <PaymentRecurrenceSelector
        paymentRecurrence={stateProxy.readRequired(uiElement.dimensions.value)}
        forbiddenMonthlyPayment={stateProxy.readRequired(uiElement.dimensions.forbiddenMonthlyPayment)}
        yearlyCommitmentHasDiscount={stateProxy.readRequired(uiElement.dimensions.yearlyCommitmentHasDiscount)}
        onPaymentRecurrenceChange={stateProxy.useWrite(uiElement.dimensions.value)}
        quote={quote}
        rcdaSelected={stateProxy.readRequired(uiElement.dimensions.rcdaSelected)}
        rcphSelected={stateProxy.readRequired(uiElement.dimensions.rcphSelected)}
        installmentFeeRate={stateProxy.read(uiElement.dimensions.installmentFeeRate)}
        rcphInstallmentFeeRate={stateProxy.read(uiElement.dimensions.rcphInstallmentFeeRate)}
        rcpaSelected={stateProxy.readRequired(uiElement.dimensions.rcpaSelected)}
        rcpaInstallmentFeeRate={stateProxy.read(uiElement.dimensions.rcpaInstallmentFeeRate)}
      />
    ) : (
      <></>
    )
  },
)

type PaymentRecurrenceSelectorProps = {
  paymentRecurrence: PaymentRecurrence
  forbiddenMonthlyPayment: boolean
  onPaymentRecurrenceChange: (value: PaymentRecurrence) => void
  yearlyCommitmentHasDiscount: boolean
  quote: AggregatedQuote
  rcdaSelected: boolean
  rcphSelected: boolean
  installmentFeeRate: TypeOfDimension<typeof installmentFeeRateDimension> | undefined
  rcphInstallmentFeeRate: TypeOfDimension<typeof rcphInstallmentFeeRateDimension> | undefined
  rcpaSelected: boolean
  rcpaInstallmentFeeRate: TypeOfDimension<typeof rcpaInstallmentFeeRateDimension> | undefined
}

export const PaymentRecurrenceSelector = memo<PaymentRecurrenceSelectorProps>(function PaymentRecurrenceSelector({
  paymentRecurrence,
  forbiddenMonthlyPayment,
  onPaymentRecurrenceChange,
  yearlyCommitmentHasDiscount,
  quote,
  rcdaSelected,
  rcphSelected,
  installmentFeeRate,
  rcphInstallmentFeeRate,
  rcpaSelected,
  rcpaInstallmentFeeRate,
}) {
  const translate = useTranslate()
  const screenType = useScreenType()

  const yearlyDiscountDisplayed = getYearlyDiscount({
    yearlyCommitmentHasDiscount,
    quote,
    rcdaSelected,
    installmentFeeRate,
    rcphSelected,
    rcphInstallmentFeeRate,
    rcpaSelected,
    rcpaInstallmentFeeRate,
  })

  const paymentRecurrenceTabs = useMemo(() => {
    return {
      monthly: translate('monthly'),
      yearly: `${translate('yearly')} ${yearlyDiscountDisplayed}`,
    }
  }, [translate, yearlyDiscountDisplayed])

  const handlePaymentRecurrenceChange = useCallback(
    (value: string) => {
      if (value !== 'monthly' && value !== 'yearly') return
      onPaymentRecurrenceChange(value)
    },
    [onPaymentRecurrenceChange],
  )

  const notificationInformation: string[] = [
    ...(forbiddenMonthlyPayment ? [translate('subscription_payment_recurrence_yearly_only')] : []),
    ...(paymentRecurrence === 'yearly' && yearlyCommitmentHasDiscount
      ? [translate('subscription_payment_recurrence_yearly_commitment')]
      : []),
  ]

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: ${screenType === 'desktop' ? 'flex-start' : 'space-between'};
          align-items: center;
          gap: ${spacing[30]};
        `}
      >
        <Text variant="subtitle">{translate('subscription_subscription_funnel_coverage_recurrence_title')}</Text>
        <Tooltip title={translate('subscription_subscription_funnel_coverage_recurrence_tooltip')}>
          <VirtualClickableArea>
            <Avatar size="30" icon="circle-info-regular" />
          </VirtualClickableArea>
        </Tooltip>
      </div>

      {!forbiddenMonthlyPayment ? (
        <TabBar
          size="large"
          tabs={paymentRecurrenceTabs}
          selectedTabId={paymentRecurrence}
          onTabChange={handlePaymentRecurrenceChange}
          fullWidth={screenType === 'mobile'}
          css={css`
            margin-top: ${spacing[50]};
          `}
        />
      ) : null}

      {notificationInformation.length ? (
        <PersistentNotification
          variant="info"
          title={translate('information')}
          css={css`
            margin-top: ${spacing[50]};
          `}
        >
          {notificationInformation.map((info) => (
            <Text key={info}>{info}</Text>
          ))}
        </PersistentNotification>
      ) : null}
    </>
  )
})

type GetYearlyDiscountParams = {
  yearlyCommitmentHasDiscount: boolean
  quote: AggregatedQuote
  rcdaSelected: boolean
  rcphSelected: boolean
  installmentFeeRate: TypeOfDimension<typeof installmentFeeRateDimension> | undefined
  rcphInstallmentFeeRate: TypeOfDimension<typeof rcphInstallmentFeeRateDimension> | undefined
  rcpaSelected: boolean
  rcpaInstallmentFeeRate: TypeOfDimension<typeof rcpaInstallmentFeeRateDimension> | undefined
}

function getYearlyDiscount({
  yearlyCommitmentHasDiscount,
  quote,
  installmentFeeRate,
  rcdaSelected,
  rcphSelected,
  rcphInstallmentFeeRate,
  rcpaSelected,
  rcpaInstallmentFeeRate,
}: GetYearlyDiscountParams): string {
  if (yearlyCommitmentHasDiscount) {
    return ` -${rateToPercentString(globalMonthlyCommitmentPenaltyTenPercentRate, 0)}`
  }

  if (rcdaSelected) {
    if (installmentFeeRate === 'none' || installmentFeeRate == undefined || ratesEqual(installmentFeeRate.rate, X0)) {
      return ''
    }
    return ` -${rateToPercentString(installmentFeeRate.rate, 0, true)}`
  }

  if (rcpaSelected) {
    if (
      rcpaInstallmentFeeRate === 'none' ||
      rcpaInstallmentFeeRate == undefined ||
      ratesEqual(rcpaInstallmentFeeRate.rate, X0)
    ) {
      return ''
    }
    return ` -${rateToPercentString(rcpaInstallmentFeeRate.rate, 0, true)}`
  }

  if (rcphSelected) {
    if (
      rcphInstallmentFeeRate === 'none' ||
      rcphInstallmentFeeRate == undefined ||
      ratesEqual(rcphInstallmentFeeRate.rate, X0)
    ) {
      return ''
    }
    return ` -${rateToPercentString(rcphInstallmentFeeRate.rate, 0, true)}`
  }

  const percentageChange = computePercentageChange(
    quote.yearlyTotalPremium,
    getCummulatedMonthlyTotalPremiumPerYear(quote),
  )

  if (rateToNumber(percentageChange) === 0) return ''

  return ` ${rateToPercentString(percentageChange, 0, true)}`
}
