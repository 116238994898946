import { isRcpaOffer, type ChooseRcpaOfferSubscriptionUiElement } from '@orus.eu/dimensions'
import { DropdownFormField, TagBadge, useLanguage } from '@orus.eu/pharaoh'
import { useCallback, useMemo } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const ChooseRcpaOfferUiElementBlock = ifStateProxy<ChooseRcpaOfferSubscriptionUiElement>(
  function ChooseRcpaOfferUiElementBlock({ uiElement, stateProxy }) {
    const language = useLanguage()
    const rcpaAvailableOffersDimension = uiElement.dimensions.rcpaAvailableOffers
    const rcpaOfferDimension = uiElement.dimensions.rcpaOffer

    const writeRcpaOfferDimension = stateProxy.useWrite(rcpaOfferDimension)

    const handleChange = useCallback(
      (newValue: string | null) => {
        if (newValue && isRcpaOffer(newValue)) {
          writeRcpaOfferDimension(newValue)
        } else writeRcpaOfferDimension(null)
      },
      [writeRcpaOfferDimension],
    )

    const rcpaOffer = useMemo(() => {
      return stateProxy.read(rcpaOfferDimension) ?? null
    }, [rcpaOfferDimension, stateProxy])

    const rcpaAvailableOffers = useMemo(() => {
      return stateProxy.read(rcpaAvailableOffersDimension) ?? []
    }, [rcpaAvailableOffersDimension, stateProxy])

    const availableOffersLabel = useMemo(
      () =>
        rcpaAvailableOffers.reduce(
          (acc, availableOffer) => {
            const label =
              uiElement.dimensions.rcpaOffer.entries.find(([key]) => key === availableOffer)?.[1].label ?? 'Offre'
            return { ...acc, [availableOffer]: label }
          },
          {} as Record<string, string>,
        ),
      [rcpaAvailableOffers, uiElement.dimensions.rcpaOffer.entries],
    )

    return (
      <DropdownFormField
        size={'small'}
        label={rcpaOfferDimension.displayNames[language]}
        badge={<TagBadge tag={rcpaOfferDimension.tags?.[0]} />}
        value={rcpaOffer}
        placeholder={rcpaOfferDimension.placeholders ? rcpaOfferDimension.placeholders[language] : undefined}
        onChange={handleChange}
        values={rcpaAvailableOffers}
        labels={availableOffersLabel}
        infoTooltip={rcpaOfferDimension.hints ? rcpaOfferDimension.hints[language] : undefined}
      />
    )
  },
)
