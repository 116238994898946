import type { OriasImmatriculation, OriasSubscriptionUiElement } from '@orus.eu/dimensions'
import { CheckboxContainer, oriasTextFieldMapper, ValidatedTextFieldFormField } from '@orus.eu/pharaoh'
import { translate } from '@orus.eu/translations'
import { useCallback, useMemo, useState } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const OriasSubscriptionUiElementBlock = ifStateProxy<OriasSubscriptionUiElement>(
  function OriasSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const oriasImmatriculationDimension = uiElement.dimensions.oriasImmatriculation
    const [value, setValue] = useState<OriasImmatriculation | undefined | null>(
      stateProxy.read(oriasImmatriculationDimension),
    )

    const handleChange = useCallback(
      (value: OriasImmatriculation | undefined) => {
        const val = value ?? null
        stateProxy.write(oriasImmatriculationDimension, val)
        setValue(val)
      },
      [oriasImmatriculationDimension, stateProxy],
    )
    const handleCheckboxChange = useCallback(
      (checked: boolean) => {
        const val = checked ? { type: 'later' as const } : null
        stateProxy.write(oriasImmatriculationDimension, val)
        setValue(val)
      },
      [oriasImmatriculationDimension, stateProxy],
    )

    const oriasLater = useMemo(() => value?.type === 'later', [value])
    return (
      <div>
        <ValidatedTextFieldFormField<OriasImmatriculation | undefined>
          disabled={oriasLater}
          size={context === 'backoffice' ? 'small' : 'large'}
          label={'Numéro immatriculation ORIAS'}
          value={value}
          placeholder={oriasLater ? undefined : '21012345'}
          onChange={handleChange}
          mapper={oriasTextFieldMapper}
          required={uiElement.required}
          highlight={uiElement.highlight}
        />
        <CheckboxContainer
          checked={oriasLater}
          onChange={handleCheckboxChange}
          size={context === 'backoffice' ? 'small' : 'medium'}
        >
          {translate('waiting_for_pending_registration_process', 'fr')}
        </CheckboxContainer>
      </div>
    )
  },
)
