import styled from '@emotion/styled'
import {
  Avatar,
  Button,
  Chip,
  colors,
  RowButtonV2,
  RowContainerV2,
  spacing,
  useDialogVisibility,
} from '@orus.eu/pharaoh'
import { memo, useCallback, useMemo, useState } from 'react'
import type { SubsidiariesSelectorContext, Subsidiary } from '../subsidiary.type'
import { isSubsidiaryCompleted } from '../utils'
import type { SubsidiaryActivity } from './SubsidiaryModal'
import { SubsidiaryModalWrapper } from './SubsidiaryModalWrapper'

type SubsidiariesSelectorProps = {
  subsidiaries: Subsidiary[]
  availableActivities: SubsidiaryActivity[]
  onDeleteSubsidiary: (subsidiary: Subsidiary) => void
  onSubmitSubsidiary: (subsidiary: Subsidiary) => void
  onUpdateSubsidiary: (subsidiary: Subsidiary) => void
  context: SubsidiariesSelectorContext
}

export const SubsidiariesSelector = memo(function SubsidiariesSelector(props: SubsidiariesSelectorProps) {
  const { subsidiaries, availableActivities, onUpdateSubsidiary, onDeleteSubsidiary, onSubmitSubsidiary, context } =
    props

  const { show: showDialog, hide: hideDialog, visible: visibleDialog } = useDialogVisibility(`manage-subsidiary-dialog`)

  const [currentSubsidiary, setCurrentSubsidiary] = useState<Subsidiary | null>(null)

  const [mode, setMode] = useState<'create' | 'update'>('create')

  const onAddSubsidiaryClick = useCallback(() => {
    setCurrentSubsidiary({ country: 'France' })
    setMode('create')
    showDialog()
  }, [showDialog])

  const handleSubmit = useCallback(
    (subsidiary: Subsidiary) => {
      if (mode === 'create' && !subsidiary.id) {
        onSubmitSubsidiary(subsidiary)
      } else {
        onUpdateSubsidiary(subsidiary)
      }
      hideDialog()
    },
    [onSubmitSubsidiary, onUpdateSubsidiary, hideDialog, mode],
  )

  const handleDelete = useCallback(
    (subsidiary: Subsidiary) => {
      onDeleteSubsidiary(subsidiary)
      hideDialog()
    },
    [onDeleteSubsidiary, hideDialog],
  )

  const handleSelect = useCallback(
    (subsidiary: Subsidiary) => {
      setCurrentSubsidiary(subsidiary)
      setMode('create')
      showDialog()
    },
    [showDialog],
  )

  const showProgress = useMemo(() => {
    return context.type === 'backoffice' || context.step === 'update'
  }, [context])

  const canAdd = useMemo(() => {
    return context.type === 'backoffice' || context.step === 'create'
  }, [context])

  return (
    <>
      {visibleDialog ? (
        <SubsidiaryModalWrapper
          onClose={hideDialog}
          onSubmitSubsidiary={handleSubmit}
          onDeleteSubsidiary={handleDelete}
          mode={mode}
          context={context}
          subsidiary={currentSubsidiary ?? {}}
          availableActivities={availableActivities}
        />
      ) : (
        <></>
      )}
      <SubsdiariesListContainer>
        {subsidiaries.length > 0 ? (
          <RowContainerV2>
            {subsidiaries.map((subsidiary) => {
              const isComplete = isSubsidiaryCompleted(subsidiary, context)
              return (
                <RowButtonV2
                  key={subsidiary.id}
                  avatarLeft={<Avatar icon="building-regular" size="30" />}
                  avatarRight={
                    <div style={{ display: 'flex', gap: spacing[30] }}>
                      {showProgress ? (
                        <Chip
                          size="small"
                          backgroundColor={colors[isComplete ? 'green' : 'orange'][200]}
                          textColor={colors[isComplete ? 'green' : 'orange'][900]}
                        >
                          {isComplete ? 'Terminé' : 'A compléter'}
                        </Chip>
                      ) : (
                        <></>
                      )}
                      <Button
                        variant="secondary"
                        icon="trash-can-regular"
                        size="small"
                        onClick={() => handleDelete(subsidiary)}
                      />
                    </div>
                  }
                  primaryText={subsidiary.companyName}
                  secondaryText={subsidiary.activities?.map((activity) => activity.name).join(', ')}
                  onClick={() => handleSelect(subsidiary)}
                />
              )
            })}
          </RowContainerV2>
        ) : (
          <></>
        )}
        {canAdd ? (
          <RowContainerV2>
            <RowButtonV2
              avatarLeft={<Avatar variant="contained" icon={'plus-solid'} size="50" />}
              primaryText="Ajouter une filiale"
              onClick={() => onAddSubsidiaryClick()}
            />
          </RowContainerV2>
        ) : (
          <></>
        )}
      </SubsdiariesListContainer>
    </>
  )
})

const SubsdiariesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};
`
