import type { Agent } from './agent.type'

export const isAgentCompleted = (agent: Agent): boolean => {
  if (
    agent.firstName &&
    agent.lastName &&
    agent.street &&
    agent.postCode &&
    agent.city &&
    agent.country &&
    agent.activities &&
    agent.activities.length > 0 &&
    agent.siren
  ) {
    return true
  }
  return false
}
