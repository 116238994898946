import { type SerializedStyles } from '@emotion/react'
import { type SelectProps, TextInputLabelWrapper, type TextInputLabelWrapperProps } from '@orus.eu/pharaoh'
import { MultipleSelect } from '@orus.eu/pharaoh/src/components/inputs/multiple-select/multiple-select'
import { memo, type ReactNode } from 'react'

type MultipleDropdownSelectableFieldProps = Omit<SelectProps, 'renderValue'> &
  Omit<TextInputLabelWrapperProps, 'children'> & { fieldClassName?: SerializedStyles } & {
    selectedValues?: Array<string | number>
    renderValue?: (value: unknown) => ReactNode
  }

export const MultipleDropdownSelectableField = memo<MultipleDropdownSelectableFieldProps>(
  function MultipleDropdownSelectableField(props) {
    return (
      <TextInputLabelWrapper
        className={props.className}
        variant={props.variant}
        label={props.label}
        badge={props.badge}
        caption={props.caption}
        infoTooltip={props.infoTooltip}
        required={props.required}
      >
        <MultipleSelect {...props} css={props.fieldClassName} />
      </TextInputLabelWrapper>
    )
  },
)
