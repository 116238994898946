import type { StringSubscriptionUiElement } from '@orus.eu/dimensions'
import { TagBadge, useLanguage, ValidatedTextFieldFormField } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../if-state-proxy'
import { mappers } from './string-element-mappers'
import { useUiElementTexts } from './use-ui-element-texts'

const disabledFieldsWhenContractSigned = new Set(['email', 'phone', 'firstName', 'lastName'])

export const StringSubscriptionUiElementBlock = ifStateProxy<StringSubscriptionUiElement>(
  function StringSubscriptionUiElementBlock({ uiElement, stateProxy, context, customerId }) {
    const uiElementTexts = useUiElementTexts(uiElement)
    const language = useLanguage()
    const dimension = uiElement.dimension
    const value = stateProxy.read(dimension)
    const handleChange = stateProxy.useWrite(dimension)

    const stringValue = value ?? ''

    const mapper = mappers[uiElement.type]
    return (
      <ValidatedTextFieldFormField
        size={context === 'backoffice' ? 'small' : 'large'}
        badge={context === 'backoffice' ? <TagBadge tag={uiElement.dimension.tags?.[0]} /> : <></>}
        disabled={!!customerId && disabledFieldsWhenContractSigned.has(dimension.name)}
        label={uiElementTexts?.label ?? dimension.displayNames[language]}
        value={stringValue}
        placeholder={dimension.placeholders ? dimension.placeholders[language] : undefined}
        onChange={handleChange}
        mapper={mapper}
        infoTooltip={uiElementTexts?.hint ?? (dimension.hints ? dimension.hints[language] : undefined)}
        aria-label={dimension.ariaLabels ? dimension.ariaLabels[language] : undefined}
        required={uiElement.required}
        highlight={uiElement.highlight}
      />
    )
  },
)
