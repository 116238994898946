import { css } from '@emotion/react'
import { assertActivity } from '@orus.eu/activity'
import { isRcpaOffer, type QuotePageBodySubscriptionUiElement, type RcpaOffer } from '@orus.eu/dimensions'
import { Button, Dialog, RadioGroup, SelectableCard, spacing, Text, useDialogVisibility } from '@orus.eu/pharaoh'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { memo, useCallback, useEffect, useMemo, useState, type ChangeEvent } from 'react'
import { HelpIcon } from '../../../../molecules/help-icon'
import type { StateProxy } from '../../subscription-v2-props'

export type RcpaOfferSelectorProps = {
  uiElement: QuotePageBodySubscriptionUiElement
  stateProxy: StateProxy
}
export const RcpaOfferSelector = memo<RcpaOfferSelectorProps>(function RcpaOfferSelector({ uiElement, stateProxy }) {
  const availableOffers = stateProxy.readRequired(uiElement.dimensions.rcpaAvailableOffers)
  const [offer, setOffer] = useState(stateProxy.readRequired(uiElement.dimensions.rcpaOffer))
  const deductiblePerActivityPerOffer = stateProxy.readRequired(uiElement.dimensions.rcpaDeductiblePerActivityPerOffer)
  const mainActivity = stateProxy.readRequired(uiElement.dimensions.activity)
  const rcpaActivities = stateProxy.readRequired(uiElement.dimensions.rcpaActivities)

  const [offerDialog, setOfferDialog] = useState<RcpaOffer | undefined>()

  const {
    show: openDialog,
    hide: closeDialog,
    visible: isVisibleDialog,
  } = useDialogVisibility('details-' + offerDialog)

  const handleOfferChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, value: string) => {
      if (isRcpaOffer(value)) {
        stateProxy.write(uiElement.dimensions.rcpaOffer, value)
        setOffer(value)
      }
    },
    [stateProxy, uiElement.dimensions.rcpaOffer],
  )

  const availableOffersLabel = useMemo(
    () =>
      availableOffers.reduce(
        (acc, availableOffer) => {
          const label =
            uiElement.dimensions.rcpaOffer.entries.find(([key]) => key === availableOffer)?.[1].label ?? 'Offre'
          return { ...acc, [availableOffer]: label }
        },
        {} as Record<string, string>,
      ),
    [availableOffers, uiElement.dimensions.rcpaOffer.entries],
  )

  useEffect(() => {
    if (offerDialog) {
      openDialog()
    }
  }, [offerDialog, openDialog])

  const closeDialogForOffer = useCallback(() => {
    setOfferDialog(undefined)
    closeDialog()
  }, [closeDialog])

  return availableOffers.length > 1 ? (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[50]};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex: 1 1;
        `}
      >
        <Text variant="subtitle">Formule</Text>
        <HelpIcon title="Cliquez sur “Détails de l’offre” pour voir les plafonds et franchises pour chacune de vos activités" />
      </div>
      <RadioGroup
        onChange={handleOfferChange}
        value={offer}
        css={css`
          gap: ${spacing[30]};
        `}
      >
        {availableOffers.map((availableOffer) => (
          <SelectableCard
            key={availableOffer}
            title={availableOffersLabel[availableOffer]}
            avatar={null}
            isActive={availableOffer === offer}
            button={
              <Button variant="text" size="small" onClick={() => setOfferDialog(availableOffer)}>
                Détails de l&apos;offre
              </Button>
            }
            radioConfig={{
              value: availableOffer,
              onRadioChange: handleOfferChange,
            }}
            subtitle={`Plafond : ${
              deductiblePerActivityPerOffer[availableOffer]?.[assertActivity(mainActivity.activity)]
                ?.rcpDommagesCorporels?.guaranteeTableFormat?.loiShortFormat
            } (Franchise ${
              deductiblePerActivityPerOffer[availableOffer]?.[assertActivity(mainActivity.activity)]
                ?.rcpDommagesCorporels?.guaranteeTableFormat?.deductibleShortFormat
            })`}
          />
        ))}
      </RadioGroup>
      {isVisibleDialog && offerDialog && (
        <Dialog
          size="large"
          title={`Plafond et franchises - ${availableOffersLabel[offerDialog]}`}
          onClose={closeDialogForOffer}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${spacing['70']};
              flex-grow: 1;
            `}
          >
            {rcpaActivities.map((activity) => (
              <div
                key={activity.activity}
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: ${spacing['30']};
                `}
              >
                <Text>{activity.displayName}</Text>
                <RowContainer variant={'border'} size={'large'}>
                  <Row
                    title="Plafond de garantie"
                    subtitle={
                      deductiblePerActivityPerOffer[offerDialog]?.[assertActivity(activity.activity)]
                        ?.rcpDommagesCorporels?.guaranteeTableFormat?.loiLongFormat
                    }
                  />
                  <Row
                    title="Franchise"
                    subtitle={
                      deductiblePerActivityPerOffer[offerDialog]?.[assertActivity(activity.activity)]
                        ?.rcpDommagesCorporels?.guaranteeTableFormat?.deductibleLongFormat
                    }
                  />
                </RowContainer>
              </div>
            ))}
          </div>
        </Dialog>
      )}
    </div>
  ) : null
})
