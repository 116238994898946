import type { ContactBannerSubscriptionUiElement } from '@orus.eu/dimensions'

import { ContactBanner } from '@orus.eu/pharaoh'
import { useShowIllustrations } from '../../../../lib/use-show-illustrations'
import { getSupportContactDetails } from '../common/get-support-contact-details'
import { ifStateProxy } from '../if-state-proxy'

export const ContactBannerSubscriptionUiElementBlock = ifStateProxy<ContactBannerSubscriptionUiElement>(
  function ContactBannerSubscriptionUiElementBlock({ stateProxy, uiElement, organization }) {
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)
    const { isManagedByOrus, helpContactDetails } = getSupportContactDetails(organization, operatingZone)
    const showIllustrations = useShowIllustrations()

    return (
      <ContactBanner
        helpContactDetails={helpContactDetails}
        useIllustrations={showIllustrations}
        isManagedByOrus={isManagedByOrus}
      />
    )
  },
)
