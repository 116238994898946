import { memo, useCallback, useMemo, useState, type ReactElement } from 'react'

import type { CompanyIdNumber } from '@orus.eu/company-id-number'
import type { OriasImmatriculation } from '@orus.eu/dimensions'
import type { SubsidiariesSelectorContext, Subsidiary } from '../subsidiary.type'
import { SubsidiaryModal, type SubsidiaryActivity } from './SubsidiaryModal'

type SubsidiaryModalWrapperProps = {
  onClose: () => void
  onSubmitSubsidiary: (subsidiary: Subsidiary) => void
  onDeleteSubsidiary: (subsidiary: Subsidiary) => void
  mode: 'create' | 'update'
  subsidiary: Subsidiary
  context: SubsidiariesSelectorContext
  availableActivities: SubsidiaryActivity[]
}

export const SubsidiaryModalWrapper = memo<SubsidiaryModalWrapperProps>(function SubsidiaryModalWrapper(
  props: SubsidiaryModalWrapperProps,
): ReactElement {
  const { onSubmitSubsidiary, onClose, onDeleteSubsidiary, mode, subsidiary, context, availableActivities } = props

  const canDelete = useMemo(() => {
    return mode === 'update'
  }, [mode])

  const [idleSubsidiary, setIdleSubsidiary] = useState<Subsidiary>(subsidiary)

  const updateSubsidiary = useCallback(
    (update: Subsidiary) => {
      const updateIdleSubsidiary = { ...idleSubsidiary, ...update }
      setIdleSubsidiary(updateIdleSubsidiary)
    },
    [idleSubsidiary],
  )

  const onSubmit = useCallback(() => {
    onSubmitSubsidiary(idleSubsidiary)
  }, [idleSubsidiary, onSubmitSubsidiary])

  const onDelete = useCallback(() => {
    if (!canDelete) return
    onDeleteSubsidiary(idleSubsidiary)
  }, [canDelete, onDeleteSubsidiary, idleSubsidiary])

  const handleChangeCompanyName = useCallback(
    (value: string) => updateSubsidiary({ companyName: value }),
    [updateSubsidiary],
  )

  const handleChangeSiren = useCallback(
    (value: CompanyIdNumber | undefined) => updateSubsidiary({ siren: value }),
    [updateSubsidiary],
  )

  const handleChangeOrias = useCallback(
    (value: OriasImmatriculation | undefined) => updateSubsidiary({ orias: value }),
    [updateSubsidiary],
  )

  const handleChangeStreet = useCallback((value: string) => updateSubsidiary({ street: value }), [updateSubsidiary])

  const handleChangeCity = useCallback((value: string) => updateSubsidiary({ city: value }), [updateSubsidiary])

  const handleChangeCountry = useCallback((value: string) => updateSubsidiary({ country: value }), [updateSubsidiary])

  const handleChangePostCode = useCallback((value: string) => updateSubsidiary({ postCode: value }), [updateSubsidiary])

  const handleActivityCheck = useCallback(
    (value: SubsidiaryActivity) => {
      updateSubsidiary({ activities: [...(idleSubsidiary.activities ?? []), value] })
    },
    [updateSubsidiary, idleSubsidiary.activities],
  )

  const handleActivityUncheck = useCallback(
    (value: SubsidiaryActivity) => {
      updateSubsidiary({ activities: idleSubsidiary.activities?.filter((activity) => activity.id !== value.id) })
    },
    [updateSubsidiary, idleSubsidiary.activities],
  )

  return (
    <SubsidiaryModal
      onClose={onClose}
      onSubmit={onSubmit}
      onDelete={onDelete}
      onCompanyNameUpdate={handleChangeCompanyName}
      onSirenUpdate={handleChangeSiren}
      onOriasUpdate={handleChangeOrias}
      onStreetUpdate={handleChangeStreet}
      onPostCodeUpdate={handleChangePostCode}
      onCityUpdate={handleChangeCity}
      onCountryUpdate={handleChangeCountry}
      onActivityCheck={handleActivityCheck}
      onActivityUncheck={handleActivityUncheck}
      subsidiary={idleSubsidiary}
      context={context}
      availableActivities={availableActivities}
    />
  )
})
