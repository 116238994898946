import type { OperatingZone } from '@orus.eu/operating-zone'
import type { HelpContactDetails } from '@orus.eu/pharaoh'
import type { OrganizationFunnelInfo } from '@orus.eu/right-access-management'
import { helpContactDetails } from '../../../../lib/help-contact-details'

export function getSupportContactDetails(
  organization: OrganizationFunnelInfo | undefined,
  operatingZone: OperatingZone,
): { isManagedByOrus: boolean; helpContactDetails: HelpContactDetails } {
  if (!organization || organization.technicalName === 'orus' || organization.isManagedByOrus) {
    return { isManagedByOrus: true, helpContactDetails: helpContactDetails[operatingZone] }
  }

  return {
    isManagedByOrus: false,
    helpContactDetails: {
      email: organization.email,
      phone: organization.phone,
    },
  }
}
