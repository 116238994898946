import { colorTokens, Dialog, Text } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'

export function RemoveMainActivityConfirmationDialog({
  onRemoveMainActivity,
  onClose,
}: {
  onRemoveMainActivity: () => void
  onClose: () => void
}): ReactElement {
  return (
    <Dialog
      title="Supprimer l'activité principale"
      style="danger"
      size="small"
      isBackoffice={true}
      primaryActionLabel="Supprimer"
      onPrimaryAction={onRemoveMainActivity}
      secondaryActionLabel="Annuler"
      onSecondaryAction={onClose}
      onClose={onClose}
    >
      <div>
        <Text variant="body2" color={colorTokens['color-text-base-basic']}>
          Si vous supprimez l&apos;activité principale, toutes les activités secondaires associées seront également
          retirées du devis.
        </Text>
      </div>
    </Dialog>
  )
}
