import type { GroupData } from '@orus.eu/backend/src/views/activity-grouping-view'
import {
  productInformationIdToProduct,
  productSelectionDimensionsByProduct,
  type ActivityBackofficeSearchSubscriptionUiElement,
  type PossibleOffer,
  type ProductInformationId,
} from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { TextInputLabelWrapper, useAsyncCallback } from '@orus.eu/pharaoh'
import type { Product } from '@orus.eu/product'
import { useState } from 'react'
import { trpc } from '../../../../../client'
import { backofficeGroupMappingLoader } from '../../../../../lib/group-mapping-util'
import { ifStateProxy } from '../../if-state-proxy'
import type { StateProxy } from '../../subscription-v2-props'
import { MainActivitySearchField } from './MainActivitySearchField'
import { SecondaryActivitiesSearchField } from './SecondaryActivitiesSearchField'

function productHasMultiActivities(
  stateProxy: StateProxy,
  uiElement: ActivityBackofficeSearchSubscriptionUiElement,
): boolean {
  return Object.keys(productSelectionDimensionsByProduct).some((product) => {
    const productSelected =
      productSelectionDimensionsByProduct[product as keyof typeof productSelectionDimensionsByProduct].name

    switch (productSelected) {
      case 'rcphSelected':
      case 'rcdaSelected':
      case 'rcpaSelected':
        return stateProxy.read(uiElement.dimensions[productSelected])
      case 'mrpwSelected':
      case 'mrphSelected':
      case 'esRcphSelected':
      case 'mutaSelected':
        return false
    }
  })
}

export const ActivityBackofficeSearchSubscriptionUiElementBlock =
  ifStateProxy<ActivityBackofficeSearchSubscriptionUiElement>(
    function ActivityBackofficeSearchSubscriptionUiElementBlock(props) {
      const { uiElement, stateProxy } = props

      const [loading, setLoading] = useState(false)

      const operatingZone = stateProxy.readRequired<OperatingZone>(uiElement.dimensions.operatingZone)
      const mainActivity = stateProxy.read(uiElement.dimensions.activity)
      const complementaryActivities = stateProxy.read(uiElement.dimensions.complementaryActivities)
      const rcphProduct = stateProxy.read(uiElement.dimensions.rcphProduct)
      const possibleOffers = stateProxy.read(uiElement.dimensions.possibleOffers)
      const maxActivitiesCount = stateProxy.read(uiElement.dimensions.maxActivitiesCount)

      const activitiesCount = 1 + (complementaryActivities?.length ?? 0)
      const isMaxActivitiesReached = maxActivitiesCount && activitiesCount >= maxActivitiesCount

      let product: Product | undefined = undefined
      if (possibleOffers) {
        const possibleOffersEntries = Object.entries(possibleOffers) as [ProductInformationId, PossibleOffer][]
        possibleOffersEntries.forEach(([productInformationId, offer]) => {
          if (offer.isRecommended) {
            product = productInformationIdToProduct[productInformationId]
          }
        })
      }

      const hasMainActivity = !!mainActivity
      const hasMultiActivities = productHasMultiActivities(stateProxy, uiElement)

      const hasEligibleMainActivity = hasMainActivity && mainActivity.activity !== 'excludedActivity'

      const selectedActivityIds = new Set([
        ...(hasMainActivity ? [mainActivity.activity] : []),
        ...(complementaryActivities?.length ? complementaryActivities.map((activity) => activity.activity) : []),
      ])

      const handleGroupSelected = useAsyncCallback(
        async (activityGroup: GroupData) => {
          setLoading(true)

          if (hasEligibleMainActivity) {
            if (isMaxActivitiesReached) return

            stateProxy.write(uiElement.dimensions.complementaryActivities, [
              ...(complementaryActivities ?? []),
              { activity: activityGroup.id, displayName: activityGroup.name },
            ])
          } else {
            const { mainActivityId } = checkDefinedAndNotNull(
              await trpc.activities.getGroupMappingForBackoffice.query({ group: activityGroup.name, operatingZone }),
            )

            stateProxy.write(uiElement.dimensions.activity, {
              activity: mainActivityId,
              displayName: activityGroup.name,
            })
          }

          setLoading(false)
        },
        [
          complementaryActivities,
          hasEligibleMainActivity,
          isMaxActivitiesReached,
          operatingZone,
          stateProxy,
          uiElement.dimensions,
        ],
      )

      return (
        <TextInputLabelWrapper label="Ajouter une activité">
          {hasMainActivity && product ? (
            <SecondaryActivitiesSearchField
              operatingZone={operatingZone}
              product={product}
              rcphProduct={rcphProduct}
              helperText={!hasMultiActivities ? 'Multi-activités indisponible pour cette assurance' : undefined}
              disabled={loading || isMaxActivitiesReached || !hasMultiActivities}
              selectedActivityIds={selectedActivityIds}
              onChange={handleGroupSelected}
            />
          ) : (
            <MainActivitySearchField
              operatingZone={operatingZone}
              disabled={loading}
              groupLoader={backofficeGroupMappingLoader}
              onChange={handleGroupSelected}
            />
          )}
        </TextInputLabelWrapper>
      )
    },
  )
