import styled from '@emotion/styled'
import { Avatar, Button, RowButtonV2, RowContainerV2, spacing, useDialogVisibility } from '@orus.eu/pharaoh'
import { memo, useCallback, useMemo, useState } from 'react'
import type { Agent, AgentActivity } from '../agent.type'
import { AgentModalWrapper } from './AgentModalWrapper'

type AgentsSelectorProps = {
  agents: Agent[]
  availableActivities: AgentActivity[]
  onDeleteAgent: (agent: Agent) => void
  onSubmitAgent: (agent: Agent) => void
  onUpdateAgent: (agent: Agent) => void
  maxAgentsCount: number
  isInBackoffice: boolean
}

export const AgentsSelector = memo(function AgentsSelector(props: AgentsSelectorProps) {
  const { agents, availableActivities, onUpdateAgent, onDeleteAgent, onSubmitAgent, maxAgentsCount, isInBackoffice } =
    props

  const { show: showDialog, hide: hideDialog, visible: visibleDialog } = useDialogVisibility(`manage-agent-dialog`)

  const [currentAgent, setCurrentAgent] = useState<Agent | null>(null)

  const [mode, setMode] = useState<'create' | 'update'>('create')

  const onAddAgentClick = useCallback(() => {
    setCurrentAgent({ country: 'France' })
    setMode('create')
    showDialog()
  }, [showDialog])

  const handleSubmit = useCallback(
    (agent: Agent) => {
      if (mode === 'create' && !agent.id) {
        onSubmitAgent(agent)
      } else {
        onUpdateAgent(agent)
      }
      hideDialog()
    },
    [onSubmitAgent, onUpdateAgent, hideDialog, mode],
  )

  const handleDelete = useCallback(
    (agent: Agent) => {
      onDeleteAgent(agent)
      hideDialog()
    },
    [onDeleteAgent, hideDialog],
  )

  const handleSelect = useCallback(
    (agent: Agent) => {
      setCurrentAgent(agent)
      setMode('create')
      showDialog()
    },
    [showDialog],
  )

  const canAdd = useMemo(() => {
    return agents.length < maxAgentsCount
  }, [agents, maxAgentsCount])

  return (
    <>
      {visibleDialog ? (
        <AgentModalWrapper
          availableActivities={availableActivities}
          onClose={hideDialog}
          onSubmitAgent={handleSubmit}
          onDeleteAgent={handleDelete}
          mode={mode}
          agent={currentAgent ?? {}}
          isInBackoffice={isInBackoffice}
        />
      ) : (
        <></>
      )}
      <AgentsListContainer>
        {agents.length > 0 ? (
          <RowContainerV2>
            {agents.map((agent) => {
              return (
                <RowButtonV2
                  key={agent.id}
                  avatarLeft={<Avatar icon="circle-user-regular" size="30" />}
                  avatarRight={
                    <Button
                      variant="secondary"
                      icon="trash-can-regular"
                      size="small"
                      onClick={() => handleDelete(agent)}
                    />
                  }
                  primaryText={agent.firstName
                    ?.concat(' ', agent.lastName ?? '')
                    .concat(' - ')
                    .concat(agent.postCode ?? '')}
                  secondaryText={agent.activities?.map((act) => act.name)?.join(', ') ?? ''}
                  onClick={() => handleSelect(agent)}
                />
              )
            })}
          </RowContainerV2>
        ) : (
          <></>
        )}
        {canAdd ? (
          <RowContainerV2>
            <RowButtonV2
              avatarLeft={<Avatar variant="contained" icon={'plus-solid'} size="50" />}
              primaryText="Ajouter un mandataire"
              onClick={() => onAddAgentClick()}
            />
          </RowContainerV2>
        ) : (
          <></>
        )}
      </AgentsListContainer>
    </>
  )
})

const AgentsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};
`
