import type { AmountSubscriptionUiElement } from '@orus.eu/dimensions'
import { TagBadge, useLanguage, ValidatedTextFieldFormField } from '@orus.eu/pharaoh'
import { amountCustomInputProps, amountMapper, positiveAmountCustomInputProps } from '../../../../lib/amount'

import { ifStateProxy } from '../if-state-proxy'
import { useUiElementTexts } from './use-ui-element-texts'

export const AmountSubscriptionUiElementBlock = ifStateProxy<AmountSubscriptionUiElement>(
  function AmountSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
    const language = useLanguage()
    const dimension = uiElement.dimension
    const value = stateProxy.read(dimension)
    const handleChange = stateProxy.useWrite(dimension)
    const uiElementTexts = useUiElementTexts(uiElement)
    return (
      <ValidatedTextFieldFormField
        size={context === 'backoffice' ? 'small' : 'large'}
        label={uiElementTexts?.label ?? dimension.displayNames[language]}
        badge={context === 'backoffice' ? <TagBadge tag={uiElement.dimension.tags?.[0]} /> : <></>}
        value={value}
        placeholder={dimension.placeholders ? dimension.placeholders[language] : undefined}
        onChange={handleChange}
        mapper={amountMapper}
        infoTooltip={uiElementTexts?.hint ?? (dimension.hints ? dimension.hints[language] : undefined)}
        InputProps={uiElement.positiveOnly ? positiveAmountCustomInputProps : amountCustomInputProps}
        required={uiElement.required}
        highlight={uiElement.highlight}
      />
    )
  },
)
