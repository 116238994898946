import { amountToNumber } from '@orus.eu/amount'
import type { EsRcphLoi, QuotePageBodySubscriptionUiElement } from '@orus.eu/dimensions'
import { LoiSliderBlock } from '@orus.eu/pharaoh'
import { memo, useCallback } from 'react'
import { sendMessage } from '../../../../../../lib/tracking/tracking'
import type { StateProxy } from '../../../subscription-v2-props'

type RcphLoiSelectorProps = {
  subscriptionId: string
  uiElement: QuotePageBodySubscriptionUiElement
  stateProxy: StateProxy
}
export const EsRcphLoiSelector = memo<RcphLoiSelectorProps>(function RcphLoiSelector({
  subscriptionId,
  uiElement,
  stateProxy,
}) {
  const value = stateProxy.readRequired(uiElement.dimensions.esRcphLoi)
  const allowedLois = stateProxy.readRequired(uiElement.dimensions.esRcphAllowedLois)
  const handleLoiChange = useCallback(
    (value: EsRcphLoi) => {
      stateProxy.write(uiElement.dimensions.esRcphLoi, value)
      sendMessage({
        subscription_id: subscriptionId,
        event: 'subscription_updated_front',
        rcph_loi: null,
        es_rcph_loi: value,
        yearly_total_premium: amountToNumber(
          stateProxy.readRequired(uiElement.dimensions.hiddenQuote).yearlyTotalPremium,
        ),
        mrph_deductible: null,
        es_rcph_deductible: null,
        rcda_deductible: null,
        option: null,
        rcda_history_takeover_years: null,
        mrph_option_pe: null,
      })
    },
    [stateProxy, subscriptionId, uiElement],
  )

  if (allowedLois.length < 2) return <></>
  return <LoiSliderBlock allowedValues={allowedLois} initialGuaranteeLimit={value} onChange={handleLoiChange} />
})
