import {
  mutaFormulaLabels,
  mutaFormulaTypeSchema,
  type ChooseMutaFormulaSubscriptionUiElement,
} from '@orus.eu/dimensions'
import { DropdownFormField, TagBadge, useLanguage } from '@orus.eu/pharaoh'
import { useCallback, useMemo } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const ChooseMutaFormulaUiElementBlock = ifStateProxy<ChooseMutaFormulaSubscriptionUiElement>(
  function ChooseMutaFormulaUiElementBlock({ uiElement, stateProxy }) {
    const language = useLanguage()
    const possibleFormulasDimension = uiElement.dimensions.possibleFormulas
    const formulaDimension = uiElement.dimensions.formula
    const clientRecommendedFormulaDimension = uiElement.dimensions.clientRecommendedFormula

    const writeFormulaDimension = stateProxy.useWrite(formulaDimension)
    const writeCustomizedFormulaDimension = stateProxy.useWrite(clientRecommendedFormulaDimension)

    const handleChange = useCallback(
      (newValue: string | null) => {
        const res = mutaFormulaTypeSchema.safeParse(newValue)
        if (res.success) {
          writeFormulaDimension(res.data)
          writeCustomizedFormulaDimension(res.data)
        } else writeFormulaDimension(null)
      },
      [writeFormulaDimension, writeCustomizedFormulaDimension],
    )

    const formula = useMemo(() => {
      return stateProxy.read(formulaDimension) ?? null
    }, [formulaDimension, stateProxy])

    const possibleFormulas = useMemo(() => {
      return stateProxy.read(possibleFormulasDimension) ?? []
    }, [possibleFormulasDimension, stateProxy])

    return (
      <DropdownFormField
        size={'small'}
        label={formulaDimension.displayNames[language]}
        badge={<TagBadge tag={formulaDimension.tags?.[0]} />}
        value={formula}
        placeholder={formulaDimension.placeholders ? formulaDimension.placeholders[language] : undefined}
        onChange={handleChange}
        values={possibleFormulas}
        labels={mutaFormulaLabels}
        infoTooltip={formulaDimension.hints ? formulaDimension.hints[language] : undefined}
      />
    )
    //return <RowContainerWithRadio items={possibleFormulas} value={formulaLabel} handleChange={handleChange} />
  },
)
