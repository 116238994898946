import { css } from '@emotion/react'
import {
  productInformationIdToProduct,
  type ActivitiesSelectorSubscriptionUiElement,
  type PossibleOffer,
  type ProductInformationId,
} from '@orus.eu/dimensions'

import {
  ActivitiesSelector,
  ActivityCoverageDialog,
  spacing,
  Text,
  useDialogVisibility,
  useUiContext,
} from '@orus.eu/pharaoh'
import type { Product } from '@orus.eu/product'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { trpc, trpcReact } from '../../../../../client'
import { assert } from '../../../../../lib/errors'
import { ActivityDetailButton } from '../../../backoffice/common/backoffice-quote-editor-v2/quote-editor-v2-risk-carrier-info'
import { ifStateProxy } from '../../if-state-proxy'
import { ActivityDialog } from './activity-dialog'
import { RemoveMainActivityConfirmationDialog } from './RemoveMainActivityConfirmationDialog'

type Activity = { id: string; name: string; description: string }
type ActivityWithProducts = Omit<Activity, 'description'> & { products: Product[] }

export const ActivitiesSelectorSubscriptionUiElementBlock = ifStateProxy<ActivitiesSelectorSubscriptionUiElement>(
  function ActivitiesSelectorSubscriptionUiElementBlock(props) {
    const { uiElement, stateProxy, context } = props

    const screenVariant = useUiContext()
    const possibleOffers = stateProxy.readRequired(uiElement.dimensions.possibleOffers)
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)
    const rcphProduct = stateProxy.read(uiElement.dimensions.rcphProduct)

    let product: Product | undefined = undefined
    const possibleOffersEntries = Object.entries(possibleOffers) as [ProductInformationId, PossibleOffer][]
    possibleOffersEntries.forEach(([productInformationId, offer]) => {
      if (offer.isRecommended) {
        product = productInformationIdToProduct[productInformationId]
      }
    })

    assert(product, 'Recommended product should always be available for activities selector block')

    const activityRaw = stateProxy.read(uiElement.dimensions.activity)
    const complementaryActivitiesRaw = stateProxy.read(uiElement.dimensions.complementaryActivities)
    const maxActivitiesCount = stateProxy.read(uiElement.dimensions.maxActivitiesCount)
    const nomenclatureVersion = stateProxy.readRequired(uiElement.dimensions.nomenclatureVersion)
    const writeMainActivity = stateProxy.useWrite(uiElement.dimensions.activity)
    const writeComplementaryActivities = stateProxy.useWrite(uiElement.dimensions.complementaryActivities)

    const activitiesIds = useMemo(() => {
      return [
        ...(activityRaw ? [activityRaw.activity] : []),
        ...(complementaryActivitiesRaw ? complementaryActivitiesRaw.map((activity) => activity.activity) : []),
      ]
    }, [activityRaw, complementaryActivitiesRaw])

    const productActivitiesScope = trpcReact.productScope.getProductScopeExclusionsV2.useQuery({
      version: nomenclatureVersion,
      activities: activitiesIds,
    })
    const scope = useMemo(() => {
      return productActivitiesScope.data?.scope ?? {}
    }, [productActivitiesScope.data])

    const mainActivity = useMemo(
      () =>
        activityRaw
          ? {
              id: activityRaw.activity,
              name: activityRaw.displayName,
              products: [],
              hasMoreDetails: scope && scope[activityRaw.activity],
            }
          : undefined,
      [activityRaw, scope],
    )

    const complementaryActivities = useMemo(
      () =>
        (complementaryActivitiesRaw ?? []).map((activity) => ({
          id: activity.activity,
          name: activity.displayName,
          products: [],
          hasMoreDetails: scope && scope[activity.activity],
        })),
      [complementaryActivitiesRaw, scope],
    )

    const [mainActivityWithProducts, setMainActivityWithProducts] = useState<ActivityWithProducts | undefined>(
      mainActivity,
    )

    const [complementaryActivitiesWithProducts, setComplementaryActivitiesWithProducts] =
      useState<ActivityWithProducts[]>(complementaryActivities)

    const state = useMemo(() => {
      return {
        activity: stateProxy.read(uiElement.dimensions.activity),
        mrphHiscoxActivitiesNames: stateProxy.read(uiElement.dimensions.mrphHiscoxActivitiesNames),
        rcphHiscoxActivitiesNames: stateProxy.read(uiElement.dimensions.rcphHiscoxActivitiesNames),
        rcphProductLabel: stateProxy.read(uiElement.dimensions.rcphProductLabel),
        mrpwWakamMainActivity: stateProxy.read(uiElement.dimensions.mrpwWakamMainActivity),
        rcdaAxeriaActivityNames: stateProxy.read(uiElement.dimensions.rcdaAxeriaActivityNames),
      }
    }, [stateProxy, uiElement.dimensions])

    useEffect(() => {
      if (context === 'selfonboarding') return

      async function loadActivitiesProducts() {
        if (!mainActivity) return

        const groupMapping = await trpc.activities.getGroupMappingForBackoffice.query({
          group: mainActivity.name,
          operatingZone,
        })

        setMainActivityWithProducts({
          ...mainActivity,
          products: groupMapping?.products ?? [],
        })

        setComplementaryActivitiesWithProducts(
          complementaryActivities.map((activity) => ({
            ...activity,
            products: groupMapping?.products ?? [],
          })),
        )
      }

      void loadActivitiesProducts()
    }, [mainActivity, operatingZone, complementaryActivities, context])

    const allSelectedActivityIds = useMemo(() => {
      if (!mainActivity) return new Set<string>()

      return new Set([mainActivity.id, ...complementaryActivities.map((activity) => activity.id)])
    }, [mainActivity, complementaryActivities])

    const [targetedActivity, setTargetedActivity] = useState<{ id: string; name: string } | undefined>(undefined)

    const {
      show: showAddActivityDialog,
      hide: hideAddActivityDialog,
      visible: visibleAddActivityDialog,
    } = useDialogVisibility('add-activity')

    const {
      show: showActivityDetailsDialog,
      hide: hideActivityDetailsDialog,
      visible: visibleActivityDetailsDialog,
    } = useDialogVisibility('activity-details')

    const {
      show: showRemoveMainActivityDialog,
      hide: hideRemoveMainActivityDialog,
      visible: visibleRemoveMainActivityDialog,
    } = useDialogVisibility('remove-main-activity')

    const handleAddActivity = useCallback(
      (activity: Activity) => {
        hideAddActivityDialog()
        const currentActivities = complementaryActivitiesRaw ?? []
        if (currentActivities.some((a) => a.activity === activity.id)) {
          return // activity already added
        }

        writeComplementaryActivities([...currentActivities, { activity: activity.id, displayName: activity.name }])
      },
      [complementaryActivitiesRaw, writeComplementaryActivities, hideAddActivityDialog],
    )

    const handleRemoveActivity = useCallback(
      (removedActivity: { id: string; name: string }) => {
        writeComplementaryActivities(
          (complementaryActivitiesRaw ?? []).filter((activity) => activity.activity !== removedActivity.id),
        )
      },
      [complementaryActivitiesRaw, writeComplementaryActivities],
    )

    const handleRemoveMainActivity = useCallback(() => {
      writeMainActivity(null)
      writeComplementaryActivities([])
      hideRemoveMainActivityDialog()
    }, [writeMainActivity, writeComplementaryActivities, hideRemoveMainActivityDialog])

    const showActivityDetails = useCallback(
      (activity: { id: string; name: string }) => {
        setTargetedActivity(activity)
        showActivityDetailsDialog()
      },
      [showActivityDetailsDialog],
    )

    const effectiveShowActivityDetails = ['rcda', 'rcph', 'rcpa'].includes(product) ? showActivityDetails : undefined

    return (
      <>
        {screenVariant !== 'backoffice' ? (
          <Text
            variant="body2Medium"
            css={css`
              margin-bottom: ${spacing[50]};
            `}
          >
            Vos activités ({maxActivitiesCount} maximum)
          </Text>
        ) : (
          <></>
        )}
        {visibleAddActivityDialog && (
          <ActivityDialog
            operatingZone={operatingZone}
            onClose={hideAddActivityDialog}
            onAddActivity={handleAddActivity}
            product={product}
            rcphProduct={rcphProduct}
            allSelectedActivityIds={allSelectedActivityIds}
          />
        )}
        {mainActivity && mainActivityWithProducts ? (
          <ActivitiesSelector
            isSelfOnboarding={context === 'selfonboarding'}
            mainActivity={context === 'selfonboarding' ? mainActivity : mainActivityWithProducts}
            complementaryActivities={
              context === 'selfonboarding' ? complementaryActivities : complementaryActivitiesWithProducts
            }
            maxActivitiesCount={maxActivitiesCount}
            onAddActivity={showAddActivityDialog}
            onMoreDetailsClick={effectiveShowActivityDetails}
            onRemoveActivity={handleRemoveActivity}
            onRemoveMainActivity={showRemoveMainActivityDialog}
            ActivityDetailButton={context === 'backoffice' ? <ActivityDetailButton state={state} /> : null}
            isJustifyExperienceTextVisible={product === 'rcda'}
          />
        ) : (
          <></>
        )}
        {visibleRemoveMainActivityDialog && (
          <RemoveMainActivityConfirmationDialog
            onClose={hideRemoveMainActivityDialog}
            onRemoveMainActivity={handleRemoveMainActivity}
          />
        )}
        {visibleActivityDetailsDialog && targetedActivity && (
          <ActivityCoverageDialog
            activity={targetedActivity.name}
            mainActivities={scope[targetedActivity.id]?.mainActivities ?? []}
            secondaryActivities={scope[targetedActivity.id]?.secondaryActivities ?? []}
            forbiddenActivities={scope[targetedActivity.id]?.forbiddenActivities ?? []}
            onClose={hideActivityDetailsDialog}
          />
        )}
      </>
    )
  },
)
