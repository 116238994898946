import { type SocialSecurityOrganismNumberSubscriptionUiElement } from '@orus.eu/dimensions'
import {
  getSocialSecurityOrganismNumberMapper,
  SocialSecurityOrganismNumberFormatter,
  TagBadge,
  useLanguage,
  ValidatedTextFieldFormField,
} from '@orus.eu/pharaoh'
import { useCallback, useState } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const SocialSecurityOrganismNumberSubscriptionUiElementBlock =
  ifStateProxy<SocialSecurityOrganismNumberSubscriptionUiElement>(
    function SocialSecurityNumberSubscriptionUiElementBlock({ uiElement, stateProxy, context }) {
      const language = useLanguage()
      const mutaSocialSecuritOrganismNumberDimension = uiElement.dimensions.mutaSocialSecurityOrganismNumber
      const mutaRegimeDimension = uiElement.dimensions.mutaRegime
      const mapper = getSocialSecurityOrganismNumberMapper(stateProxy.read(mutaRegimeDimension))
      const [value, setValue] = useState(stateProxy.read(mutaSocialSecuritOrganismNumberDimension))
      const handleChange = useCallback(
        (value: string | undefined) => {
          stateProxy.write(mutaSocialSecuritOrganismNumberDimension, value)
          if (value) {
            setValue(value)
          }
        },
        [mutaSocialSecuritOrganismNumberDimension, stateProxy],
      )

      return (
        <div>
          <ValidatedTextFieldFormField
            size={context === 'backoffice' ? 'small' : 'large'}
            label={mutaSocialSecuritOrganismNumberDimension.displayNames[language]}
            badge={
              context === 'backoffice' ? <TagBadge tag={mutaSocialSecuritOrganismNumberDimension.tags?.[0]} /> : <></>
            }
            value={value}
            placeholder={'01 011 0000'}
            onChange={handleChange}
            mapper={mapper}
            infoTooltip={
              mutaSocialSecuritOrganismNumberDimension.hints
                ? mutaSocialSecuritOrganismNumberDimension.hints[language]
                : undefined
            }
            InputProps={{ inputComponent: SocialSecurityOrganismNumberFormatter }}
            caption="Exemple : 01 751 0000"
            required={uiElement.required}
            highlight={uiElement.highlight}
          />
        </div>
      )
    },
  )
